var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"route-map-dashboard"},[_c('div',{staticClass:"route-map-container"},[_c('div',{staticClass:"route-map",staticStyle:{"display":"flex","justify-content":"center","margin":"0 auto"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],ref:"loadingImage",staticClass:"skeleton-box",style:(_vm.loadingStyle)}),(_vm.isLoadImageDone)?_c('v-stage',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"stage",style:(_vm.stageHeight),attrs:{"config":_vm.configKonva}},[_c('v-layer',{ref:"layer"},[_c('v-image',{staticStyle:{"border-radius":"15px"},attrs:{"config":{
                                    image: _vm.image,
                                    width: _vm.configKonva.width,
                                    height: _vm.imageHeight,
                                }}}),_vm._l((_vm.routes),function(arrow){return _c('v-arrow',{key:arrow.id,attrs:{"config":{
                                    x: 0,
                                    y: 0,
                                    tension: 0.2,
                                    points: _vm.getWayOfArrow(arrow),
                                    pointerLength: 6,
                                    pointerWidth: 6,
                                    fill: 'White',
                                    shadowColor: arrow['color'],
                                    shadowBlur: 0,
                                    stroke: arrow['color'],
                                    lineCap: 'round',
                                    opacity: 0.9,
                                    strokeWidth:8,
                                    scale:4,
                                }}})}),_vm._l((_vm.percentages),function(percentage,index){return _c('v-text',{key:index,attrs:{"config":_vm.getText(percentage)}})})],2)],1):_vm._e()],1),_c('button',{staticClass:"prev navigate-btn ",class:[_vm.isCompare ? 'top-small' : 'top-big'],on:{"click":_vm.prev}},[_vm._v("❮ ")]),_c('button',{staticClass:"next navigate-btn ",class:[_vm.isCompare ? 'top-small' : 'top-big'],on:{"click":_vm.next}},[_vm._v("❯ ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }