<template>
    <div
        class="sidebar"
        ref="sidebar"
        @mouseover="hideSubmenuIcons"
        @mouseleave="showSubmenuIcon"
    >
        <div class="logo">
            <a
                href="https://cxview.ai/"
                class="simple-text logo-mini"
                target="_blank"
            >
                <div>
                    <img :src="logo" alt="logo" style="height: 48px; width: 48px;"/>
                </div>
            </a>
            <a
                href="https://cxview.ai/"
                class="simple-text logo-normal logo-text-container"
                target="_blank"
            >
                <span class="logo-title">CXView</span>
                <span class="logo-tagline">Smart Retail Solution</span>
            </a>
<!--            <div class="navbar-minimize">-->
<!--                <md-button-->
<!--                    id="minimizeSidebar"-->
<!--                    class="md-round md-just-icon md-transparent"-->
<!--                    @click="minimizeSidebar"-->
<!--                >-->
<!--                    <i class="material-icons text_align-center visible-on-sidebar-regular"-->
<!--                    >more_vert</i-->
<!--                    >-->
<!--                    <i-->
<!--                        class="material-icons design_bullet-list-67 visible-on-sidebar-mini"-->
<!--                    >view_list</i-->
<!--                    >-->
<!--                </md-button>-->
<!--            </div>-->
        </div>
        <div class="sidebar-wrapper" ref="sidebarScrollArea">
            <ul class="nav">
                <li
                    class="report"
                    @click.prevent="collapseSubMenu('dashboard')"
                    ref="dashboardMenu"
                >
                    <router-link
                        :to="'/dashboard'"
                        v-if="isShoppingMall==false"
                    >
                        <md-icon>fact_check</md-icon>
                        <p>{{ $t('menu.Home') }}</p>
                    </router-link>
                    <router-link
                        :to="'/dashboard-shoppingmall'"
                        v-if="isShoppingMall==true"
                    >
                        <md-icon>fact_check</md-icon>
                        <p>{{ $t('menu.Home') }}</p>
                    </router-link>

                </li>
                <li
                    class="report"
                    @click.prevent="collapseSubMenu('analysis')"
                    ref="analysisMenu"
                >
                    <a v-bind:class="{'active-menu': activeMenuName === 'analysis'}">
                        <md-icon>pie_chart</md-icon>
                        <p class="menu-text">
                            {{ $t('menu.Analysis') }}
                            <md-icon class="caret" v-show="expandMenu !== 'analysisExpandLess'">expand_more</md-icon>
                            <md-icon class="caret" v-show="expandMenu === 'analysisExpandLess'">expand_less</md-icon>
                        </p>
                    </a>
                    <div
                        class="submenu-container"
                        ref="analysis"
                        id="analysis"
                    >
                        <ul class="nav">
                            <li @click.stop="activeMenu('analysis')">
                                <router-link :to="'/people-counting/detail-report-counting'" class="nav-link" v-if="isShoppingMall==false">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">bar_chart</md-icon>
                                    <p class="submenu">{{ $t('menu.Report') }}</p>
                                </router-link>
                                <router-link :to="'/people-counting/report-shoppingmall'" class="nav-link" v-if="isShoppingMall==true">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">bar_chart</md-icon>
                                    <p class="submenu">{{ $t('menu.Report') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('analysis')">
                                <router-link :to="'/people-counting/zoneanalysis-shoppingmall'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">bubble_chart</md-icon>
                                    <p class="submenu">{{ $t('menu.Heatmap') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('analysis')">
                                <router-link :to="'/people-counting/routemap'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">fork_right</md-icon>
                                    <p class="submenu">{{ $t('menu.Routemap') }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li
                    @click.prevent="collapseSubMenu('customerManagement')"
                    ref="customerManagementMenu"
                >
                    <a v-bind:class="{'active-menu': activeMenuName === 'customerManagement'}">
                        <md-icon>supervised_user_circle</md-icon>
                        <p class="menu-text">
                            {{ $t('menu.CustomerManagement') }}
                            <md-icon class="caret" v-show="expandMenu !== 'customerManagementExpandLess'">expand_more
                            </md-icon>
                            <md-icon class="caret" v-show="expandMenu === 'customerManagementExpandLess'">expand_less
                            </md-icon>
                        </p>
                    </a>
                    <div
                        class="submenu-container"
                        ref="customerManagement"
                        id="customerManagement"
                    >
                        <ul class="nav">
                            <li @click.stop="activeMenu('customerManagement')">
                                <router-link :to="'/face-recogition/manage-customer'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">recent_actors</md-icon>
                                    <p class="submenu">{{ $t('menu.CustomerInformation') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('customerManagement')">
                                <router-link :to="'/face-recogition/report-face'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">history</md-icon>
                                    <p class="submenu">{{ $t('menu.History') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('customerManagement')">
                                <router-link :to="'/face-recogition/report-customer'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">history</md-icon>
                                    <p class="submenu">{{ $t('menu.CustomerReport') }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li
                    @click.prevent="collapseSubMenu('employeeManagement')"
                    ref="employeeManagementMenu"
                    id="employeeManagementMenu"
                >
                    <a v-bind:class="{'active-menu': activeMenuName === 'employeeManagement'}">
                        <md-icon>portrait</md-icon>
                        <p class="menu-text">
                            {{ $t('menu.EmployeeManagement') }}
                            <md-icon class="caret" v-show="expandMenu !== 'employeeManagementExpandLess'">expand_more
                            </md-icon>
                            <md-icon class="caret" v-show="expandMenu === 'employeeManagementExpandLess'">expand_less
                            </md-icon>
                        </p>
                    </a>
                    <div
                        class="submenu-container"
                        ref="employeeManagement"
                        id="employeeManagement"
                    >
                        <ul class="nav">
                            <li @click.stop="activeMenu('employeeManagement')">
                                <router-link :to="'/attendence/manage-employee'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">switch_account</md-icon>
                                    <p class="submenu">{{ $t('menu.EmployeeInformation') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('employeeManagement')">
                                <router-link :to="'/attendence/attendence-history'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">checklist</md-icon>
                                    <p class="submenu">{{ $t('menu.Timesheets') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('employeeManagement')">
                                <router-link :to="'/attendence/behavior-statistics'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">analytics</md-icon>
                                    <p class="submenu">{{ $t('menu.BehavioralStatistics') }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li
                    @click.prevent="collapseSubMenu('setting')"
                    ref="settingMenu"
                    id="settingMenu"
                >
                    <a v-bind:class="{'active-menu': activeMenuName === 'setting'}">
                        <md-icon>settings</md-icon>
                        <p class="menu-text">
                            {{ $t('menu.Settings') }}
                            <md-icon class="caret" v-show="expandMenu !== 'settingExpandLess'">expand_more</md-icon>
                            <md-icon class="caret" v-show="expandMenu === 'settingExpandLess'">expand_less</md-icon>
                        </p>
                    </a>
                    <div
                        class="submenu-container"
                        ref="setting"
                        id="setting"
                    >
                        <ul class="nav">
                            <li @click.stop="activeMenu('setting')" v-if="role === 'boss'">
                                <router-link :to="'/user-management/manage-list-user'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">manage_accounts</md-icon>
                                    <p class="submenu">{{ $t('menu.UserSettings') }}</p>
                                </router-link>
                            </li>
                            <li @click.stop="activeMenu('setting')">
                                <router-link :to="'/user'" class="nav-link">
                                    <md-icon v-bind:class="{'hide-icon': !isShowSubIcon}">account_circle</md-icon>
                                    <p class="submenu">{{ $t('menu.AccountSettings') }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import utils from "@/utils";

export default {
    name: "sidebar",
    components: {},

    props: {
        logo: {
            type: String,
            default: "/img/cxview-logo.png"
        }
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        collapseSubMenu(submenuName) {
            if (submenuName === "dashboard") {
                this.activeMenuName = ""

                this.listSubMenuName.forEach((menuName) => {
                    if (this.$refs[menuName] !== undefined && submenuName !== menuName) {
                        if (document.getElementById(menuName).classList.contains("transition-menu")) {
                            document.getElementById(menuName).classList.remove("transition-menu")
                            this.expandMenu = menuName + "ExpandMore"
                        }
                    }
                })

                return
            }

            if (!document.getElementById(submenuName).classList.contains("transition-menu")) {
                this.expandMenu = submenuName + "ExpandLess"
                document.getElementById(submenuName).classList.add("transition-menu")

                this.listSubMenuName.forEach((menuName) => {
                    if (this.$refs[menuName] !== undefined && submenuName !== menuName) {
                        document.getElementById(menuName).classList.remove("transition-menu")
                    }
                })

            } else {
                document.getElementById(submenuName).classList.remove("transition-menu")
                this.expandMenu = submenuName + "ExpandMore"
            }

        },
        hideSubmenuIcons() {
            this.isShowSubIcon = false
        },
        showSubmenuIcon() {
            this.isShowSubIcon = true
        },
        activeMenu(menuName) {
            this.activeMenuName = menuName
        }
    },
    computed: {},
    data() {
        return {
            listSubMenuName: ["dashboard", "analysis", "customerManagement", "setting", "employeeManagement"],
            activeMenuName: "",
            role: "",
            isShowSubIcon: true,
            expandMenu: "",
            isShoppingMall: true
        }
    },
    beforeMount() {
        
    },
    mounted() {
        this.role = utils.get_role()
        if (localStorage.getItem('accountType')=='stores') {
            this.isShoppingMall = false
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        } 
    }
};
</script>
<style lang="scss" scoped>
.sidebar-mini .sidebar, .sidebar-mini .sidebar .sidebar-wrapper {
    background-color: #fff;
}

.sidebar .logo, .off-canvas-sidebar .logo {
    background-color: #FFFFFF;
}

.sidebar-wrapper {
    padding-top: 16px;

    ul {
        list-style-type: none;
        padding: 0;
        margin-left: 0px;

        li {
            margin-bottom: 8px;

            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8C8C8C;
            }

            &:hover > a {
                p {
                    color: #276EFF;
                }

                .md-icon {
                    color: #276EFF;
                }
            }
        }
    }
}

.submenu-container {
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 1;
    max-height: 0;
    transition: .3s ease all;
}

@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.logo-mini {
    margin: 0 16px 0 16px;
}

.logo-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #000000;
}

.logo-tagline {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8C8C8C;
}

.logo-text-container {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

}

.hide-icon {
    opacity: 0 !important;
}

.menu-text {
    width: 100%;
}

.transition-menu {
    max-height: 500px !important;
    transition: .3s ease-in-out 0s;
}
</style>
