<template>
    <div>
        <div class="detail-report-main-header">
            <div class="detail-report-title">{{ $t('Routemap.HeatmapAnalysis') }}</div>
            <div class="md-layout" style="margin-top:15px">
                <div class="md-layout-item md-size-20">
                    <md-field>
                        <label>{{ $t('Routemap.SelectStore') }}</label>
                        <md-select v-model="selectListGroup">
                            <md-option
                                v-for="group in listGroup"
                                v-bind:key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="search-container choose-time md-layout-item md-size-20" style="margin-left:10px">
                    <v-date-picker v-model="range" is-range :locale="lang">
                        <template v-slot="{ inputEvents }">
                            <div class="flex justify-center items-center">
                                <input
                                    :value="displayDateRange()"
                                    v-on="inputEvents.start"
                                    class="base-input"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                        <img class="ml-16" src="/img/calendar.png" />
                </div>
            </div>
            <div class="main-content-select-date" style="margin-top:15px">
                <div class="select-date-main-title" style="margin-left:1%">
                    {{ $t('Routemap.FootfallHeatmapReportFrom') }} <b>{{ dateStart }}</b> {{ $t('Routemap.To') }}
                    <b>{{ dateEnd }}</b>
                </div>
            </div>
            <div style="padding-top: 3%">
                <RoutemapDashboard
                    :listCameraID="listCameraID"
                    :dateRange="rangeTimestamp"
                    :isCompare="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import {Api} from "@/api";
import RoutemapDashboard from "@/components/RoutemapDashboard";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");
const getListCameraModuleRouteMap = Api.get("getListCameraModuleRouteMap");
export default {
    components: {
        RoutemapDashboard,
    },
    mounted() {
        this.lang = localStorage.lang === "vn" ? "vi" : "en"
        this.range = {
            start: moment().startOf("day"),
            end: moment().startOf("day"),
        }
        this.rangeTimestamp = {
            start: 0,
            end: 0
        }
        this.dateStart = this.getFullDate(this.range.start)
        this.dateEnd = this.getFullDate(this.range.end)
        this.getListCameraID("")
    },
    props: {
        listGroup: {
            type: Array,
        }
    },
    watch: {
        range(val) {
            this.rangeTimestamp = {
                start: moment(val.start).startOf("day").unix(),
                end: moment(val.end).startOf("day").unix()
            }
            this.dateStart = this.getFullDate(val.start)
            this.dateEnd = this.getFullDate(val.end)
        },
        selectListGroup(val) {
            for (let i = 0; i < this.listGroup.length; i++) {
                if (this.listGroup[i].id === val) {
                    this.selectGroupName = this.listGroup[i].name;
                }
            }
            this.getListCameraID(val)
        },

    },
    data() {
        return {
            lang: "vn",
            range: {
                start: 0,
                end: 0,
            },
            selectListGroup: "",
            dateStart: "",
            dateEnd: "",
            selectGroupName: "",

            routes: [],
            listCameraID: [],
            rangeTimestamp: {},
        }
    },
    methods: {
        displayDateRange() {

            return moment(this.range.start).locale("vi").format("DD/MM") +
                " - " +
                moment(this.range.end).locale("vi").format("DD/MM");
        },
        getFullDate(val) {
            try {
                let date = val._d.getDate()
                if (date < 10) {
                    date = "0" + date.toString()
                }
                let month = val._d.getMonth() + 1
                if (month < 10) {
                    month = "0" + month.toString()
                }
                let year = val._d.getFullYear()
                return date + "/" + month + "/" + year
            } catch (error) {
                let date = val.getDate()
                if (date < 10) {
                    date = "0" + date.toString()
                }
                let month = val.getMonth() + 1
                if (month < 10) {
                    month = "0" + month.toString()
                }
                let year = val.getFullYear()
                return date + "/" + month + "/" + year
            }

        },
        getListCameraID(groupID) {
            let self = this;
            getListCameraModuleRouteMap.get(groupID).then(response => {
                self.listCameraID = response.data.data
            })
        },
    }

}
</script>

<style lang="scss">
.detail-report-main-container {

    .detail-report-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .detail-select-container {
        display: flex;

        .select-area {
            width: 20%;
            margin-left: auto;
            margin-top: 0;
        }
    }

    .note-conversion-rate {
        padding-left: 25px;

        h4 {
            margin: 0;
            color: #ff6459;
            font-weight: bold;
        }

        ul > li {
            font-size: 15px;
            color: #7f7f7f;
            margin: 20px 0 0 40px;
        }
    }
}
.detail-report-main-header {
    .choose-time {
        display: flex;
        align-items: center;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        justify-content: space-between;
        padding: 8px;
        background: #fff;
        input {
            border: none;
        }
        img {
            width: 20px;
            height: 20px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .detail-report-main-container {
        gap: 30px;

        .note-conversion-rate {
            ul > li {
                margin: 13px 0 0 30px;
            }
        }
    }
}
</style>