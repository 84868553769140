<template>
    <div
        data-notify="container"
        class="alert "
        role="alert"
        :class="[verticalAlign, horizontalAlign, alertType]"
        :style="customPosition"
        data-notify-position="top-center"
    >
        <!-- <i data-notify="icon" class="material-icons" v-if="icon">{{ icon }}</i> -->

        <span
            data-notify="message"
            v-html="message"
            class="span-container"
        >
    </span>
        <div @click="close()" class="close-button"> <span class="material-icons icon-up">close</span></div>

    </div>
</template>
<script>
export default {
    name: "notification",
    props: {
        message: String,
        icon: String,
        verticalAlign: {
            type: String,
            default: "top"
        },
        horizontalAlign: {
            type: String,
            default: "center"
        },
        type: {
            type: String,
            default: "info"
        },
        timeout: {
            type: Number,
            default: 10500
        },
        timestamp: {
            type: Date,
            default: () => new Date()
        }
    },
    data() {
        return {
            elmHeight: 0
        };
    },
    computed: {
        hasIcon() {
            return this.icon && this.icon.length > 0;
        },
        alertType() {
            return `alert-${this.type}`;
        },
        customPosition() {
            let initialMargin = 20;
            let alertHeight = this.elmHeight + 10;
            let sameAlertsCount = this.$notifications.state.filter(alert => {
                return (
                    alert.horizontalAlign === this.horizontalAlign &&
                    alert.verticalAlign === this.verticalAlign &&
                    alert.timestamp <= this.timestamp
                );
            }).length;
            
            
            let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
            let styles = {};
            if (this.verticalAlign === "top") {
                styles.top = `${pixels}px`;
            } else {
                styles.bottom = `${pixels}px`;
            }
            return styles;
        }
    },
    methods: {
        close() {
            this.$emit("on-close", this.timestamp);
        }
    },
    mounted() {
        this.elmHeight = this.$el.clientHeight;
        if (this.timeout) {
            setTimeout(this.close, this.timeout);
        }
    }
};
</script>
<style lang="scss" scoped>
.alert {
    border-radius: 10px;
    display: flex;
    padding: 15px;

    .span-container {
        width: 100%;
        cursor: pointer;

        ::v-deep .main-noti-container {
            display: flex;

            .profile-image-noti {
                width: 25%;
                height: 48px;
                img {
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .profile-name-noti-container {
                .profile-name-noti {
                    font-size: 18px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    //height: 100%;
                }
                .profile-visit-noti {
                    font-size: 18px;
                }
                width: 80%;
            }
        }
    }

    .close-button {
        font-size: 20px;
        margin-left: auto;
        cursor: pointer;
        height: 100%;

        &:hover{
            opacity: 40%;
        }
    }
}
.alert-member-first-time {
    background-color: #f5f5f5;
    color: #989898;
}

.alert-member-familiar {
    background-color: #ffeae9;
    color: #ff5050;
}
.alert-member-vip {
    background-color: #f2ecff;
    color: #a277ff;
}

@media screen and (max-width: 991px) {
    .alert {
        width: auto !important;
        margin: 0 10px;

        &.left {
            left: 0 !important;
        }
        &.right {
            right: 0 !important;
        }
        &.center {
            margin: 0 10px !important;
        }
    }
}

.alert {
    z-index: 100;
    position: fixed;
    width: 350px;

    &.center {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin: 0 auto;
    }

    &.left,
    &.right,
    &.center {
        margin-top: 10px;
    }

    &.left {
        left: 20px;
    }
    &.right {
        right: 20px;
    }
}

@media only screen and (max-width: 1280px) {
    .alert {
        padding: 15px;
        .span-container {
            ::v-deep .main-noti-container {
                gap: 10px;
                .profile-image-noti {
                    width: 80px;
                    height: 80px;
                }

                .profile-name-noti-container {
                    .profile-name-noti {
                        font-size: 15px;
                    }
                    .profile-visit-noti {
                        font-size: 14px;
                    }
                }
            }
        }

        .close-button {
            font-size: 18px;
        }
    }
}
</style>
