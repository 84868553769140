<template>
    <transition name="form-modal">
        <div v-show="open" class="wrapper-form-modal">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: "FormModal",

    props: {
        open: {
            type: Boolean
        }
    }
};
</script>

<style lang="scss" scoped>
/* transition */

/* ********************* */
.wrapper-form-modal {
    cursor: default;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);

    .button-close-modal {
        color: #000;
        margin-left: auto;
        cursor: pointer;
        padding: 20px 20px 0;
    }
}
</style>
