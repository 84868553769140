<template>
    <div class="main-user-table">
        <div class="search-container">
            <img src="/img/loupe.png" />
            <input
                class="base-input"
                :placeholder="$t('Settings.Search')"
                @input="debounceInput"
            />
        </div>
        <b-table
            id="user-table"
            :items="filtered"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            hover
        >
            <template v-slot:cell(name)="data">
                <div class="guest-name" :title="data.value">
                    {{ data.value }}
                </div>
            </template>

            <template v-slot:cell(email)="data">
                {{ data.value }}
            </template>

            <template v-slot:cell(status)="data">
                <div v-if="data.value === true" class="box-guest vip">{{ $t('Settings.Active') }}</div>
                <div v-else class="box-guest">{{ $t('Settings.Unactive') }}</div>
            </template>

            <template v-slot:cell(action)="data">
                <div class="manage-user-actions">
                    <div
                        class="btn-action btn-edit-user"
                        @click="clickEditUser(data.item)"
                    >
                        <img :src="imageEdit" />
                    </div>
                </div>
            </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            ref="pagination"
            :per-page="perPage"
            class="pagination-people-table"
            aria-controls="my-table"
        >
            <template #page="{ page, active }">
                <b v-if="active">{{ page }}</b>
                <i v-else>{{ page }}</i>
            </template>
        </b-pagination>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import { Api } from "@/api/index";
import _ from "lodash";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const listSubAcoount = Api.get("listSubAcoount");

export default {
    props: {},
    mounted() {
        listSubAcoount.get().then((response) => {
            this.items = response.data.data;
            if (this.items == null) {
                this.items = [];
                this.totalRows = 0;
            }
            this.totalRows = this.items.length;
        });
    },

    data() {
        var self = this
        return {
            filters: "",
            imageEdit: "/img/pencil.png",
            perPage: 5,
            currentPage: 1,
            totalRows: 0,
            fields: [
                {
                    key: "name",
                    label: self.$t("Settings.Name"),
                },
                {
                    key: "email",
                    label: self.$t("Settings.Email"),
                },
                {
                    key: "status",
                    label: self.$t("Settings.Status"),
                },
                {
                    key: "action",
                    label: self.$t("Settings.Edit"),
                },
            ],
            items: [],
        };
    },
    methods: {
        clickEditUser(val) {
            this.$router.push({
                name: "Sửa thông tin người dùng",
                params: {
                    id: val.id,
                    data: val.id,
                },
            });
        },
        refresh() {
            listSubAcoount.get().then((response) => {
                this.items = response.data.data;
                if (this.items == null) {
                    this.items = [];
                    this.totalRows = 0;
                }
                this.totalRows = this.items.length;
            });
        },
        debounceInput: _.debounce(function (e) {
            this.filters = e.target.value;
        }, 500),
       
    },
    computed: {
        filtered() {
            const filtered = this.items.filter((item) => {
                return Object.keys(this.filters).every(
                    (key) =>
                        String(item["name"])
                            .toLowerCase()
                            .includes(this.filters.toLowerCase()) ||
                        String(item["email"])
                            .toLowerCase()
                            .includes(this.filters.toLowerCase()),
                    (this.currentPage = 1)
                );
            });
            return filtered.length > 0 ? filtered : null;
        },
    },
    watch: {
        filtered(val) {
            if (val == null) {
                this.totalRows = 0;
            } else {
                this.totalRows = val.length;
            }
        },
    },
};
</script>

<style lang="scss">
.main-user-table {
    .search-container {
        width: 26%;
        border: 2px solid #eaeaea;
        border-radius: 10px;
        padding: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        position: relative;
        margin: 20px;
        gap: 10px;

        .base-input {
            width: 100%;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 15px;
            border-color: transparent;
        }
        img {
            width: 20px;
            height: 20px;
        }
        .date-range-attendance {
            position: absolute;
            top: 100%;
        }
    }
    #user-table {
        width: 100%;
        margin-bottom: 1rem;
        color: #495057;
        border-collapse: collapse;
        table-layout: fixed;

        td {
            word-wrap: break-word;
            white-space: normal;
        }

        thead td {
            cursor: default;
            text-align: center;
        }
        thead tr th:nth-child(2),
        tr td:nth-child(2) {
            width:25%;
            text-align: center;
        }
        thead tr th:nth-child(3),
        tr td:nth-child(3) {
            text-align: center;
            width: 25%;
        }

        thead tr th:nth-child(1) {
            width: 25%;
        }

        thead tr th:nth-child(5) {
            width: 25%;
        }

        tbody td {
            text-align: center;
            .profile-image {
                margin: auto;
            }
            .manage-user-actions {
                display: flex;
                gap: 15px;
                justify-content: center;

                .btn-action {
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    display: flex;
                    cursor: pointer;

                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .btn-edit-user {
        background-color: #fdc02e;
    }

    .pagination-people-table {
        float: right;
    }
    tbody td .box-guest {
        margin: 0 auto;
        width: 102px;
        height: 30px;
        border: 1px solid transparent;
        background-color: #bcbcbc;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }

    tbody td .vip {
        background-color: #6a2ce7;
    }
}
</style>
