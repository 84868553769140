<template>
    <BaseModal :open="open">
        <div class="employee-select-option-container">
            <div class="select-option-header">
                <div class="main-title">{{ $t('Customers.Select') }}</div>
                <div class="btn-close" @click="onCancel">X</div>
            </div>

            <div class="button-conntainer">
                <div class="btn-option" @click="showFilesComponent">
                    {{ $t('Customers.Import') }}
                </div>
                <div class="btn-option" @click="redirectAddEmployee">
                    {{ $t('Customers.ImportByHand') }}
                </div>
            </div>

            <div
                v-show="isShowFiles"
                :class="{ dropbox: true, ['dropbox--active']: isDrag }"
                @dragenter.prevent="handleDragOn"
                @dragover.prevent
                @dragleave.prevent="handleDragOff"
                @drop.prevent="handleDrop"
                v-if="this.isGuest != true"
            >
                <form class="fileForm">
                    <p class="fileDescription">{{ $t("Customers.DropHere") }}</p>

                    <ul v-if="files.length" class="fileList">
                        <li v-for="(file, idx) in files" :key="idx">
                            <button
                                type="button"
                                class="fileButton"
                                @click="removeFile(idx)"
                            >
                                X
                            </button>
                            <span>{{ file.name }}</span>
                        </li>
                    </ul>

                    <input
                        id="fileInput"
                        type="file"
                        class="fileInput"
                        @change="handleFilesInput"
                        multiple
                    />

                    <label class="fileLabel" for="fileInput">
                        {{ $t("Customers.AttachFile") }}
                    </label>

                    <button
                        type="button"
                        class="fileSubmit"
                        @click="uploadFiles"
                    >
                        {{ $t("Customers.Submit") }}
                    </button>
                </form>
            </div>
            <div v-show="isShowFiles" v-else>Coming soon :p !!</div>
        </div>
    </BaseModal>
</template>

<script>
import { BaseModal } from "@/components";
import { Api } from "@/api/index";
const addExcelEmployee = Api.get("addExcelEmployeeV3");

export default {
    components: {
        BaseModal,
    },

    data() {
        return {
            isShowFiles: false,
            isDrag: false,
            files: [],
        };
    },

    props: {
        open: {
            type: Boolean,
        },
        isGuest: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onCancel() {
            this.$emit("cancel", false);
        },
        redirectAddEmployee() {
            if (this.isGuest) {
                localStorage.removeItem("idGuest");
                this.$router.push({
                    name: "Thêm khách hàng",
                });
            } else {
                this.$router.push({
                    name: "Thêm nhân viên",
                });
            }
        },
        showFilesComponent() {
            this.isShowFiles = !this.isShowFiles;
        },
        removeFile(index) {
            this.files.splice(index, 1);
            //xoa string value trong file de co the re-up lai file
            document.querySelector("#fileInput").value = "";
        },

        handleDragOn() {
            this.isDrag = true;
        },

        handleDragOff() {
            this.isDrag = false;
        },

        handleDrop(e) {
            this.files = [];
            const files = [...this.files, ...e.dataTransfer.files];

            this.isDrag = false;
            if (files.length == 1) {
                if (
                    [
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ].includes(e.dataTransfer.files[0].type)
                ) {
                    this.handleFiles(files);
                } else {
                    this.$swal(
                        "Tệp phải là Excel với định dạng xlsx",
                        "",
                        "warning"
                    );
                }
            } else {
                this.$swal("Chỉ được up 1 file", "", "warning");
            }
        },

        handleFilesInput(e) {
            this.files = [];
            let files = [...this.files, ...e.target.files];
            if (files.length == 1) {
                if (
                    [
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ].includes(e.target.files[0].type)
                ) {
                    this.handleFiles(files);
                } else {
                    this.$swal(
                        "Tệp phải là Excel với định dạng xlsx",
                        "",
                        "warning"
                    );
                }
            } else {
                this.$swal("Chỉ được up 1 file", "", "warning");
            }
        },

        handleFiles(files) {
            this.files = [...files];
        },

        uploadFiles() {
            const formData = new FormData();

            this.files.forEach((file, idx) => formData.append("file", file));

            addExcelEmployee
                .post(formData)
                .then((response) => {
                    this.$swal.close();
                    this.$swal(this.$t("EmployeeResponse."+response.data.message.replace(".","")), "", "success").then(
                        this.$router.push({
                            name: "Quản lý nhân viên",
                        })
                    );

                    this.onCancel();
                })
                .catch((error) => {
                    this.$swal.close();
                    this.$swal(this.$t("EmployeeResponse."+error.response.data.message.replace(".","")), "", "warning");
                });
        },
    },
};
</script>

<style lang="scss">
.employee-select-option-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: white;
    width: 25%;
    border-radius: 10px;

    .select-option-header {
        display: flex;
        justify-content: space-between;

        .main-title {
            font-size: 20px;
            font-weight: bold;
        }

        .btn-close {
            cursor: pointer;
            font-weight: bold;
        }
    }

    .button-conntainer {
        display: flex;
        gap: 50px;
        cursor: pointer;
        justify-content: space-between;
        cursor: default;

        .btn-option {
            border: 1px solid #fff;
            background-color: #1e90ff;
            color: #fff;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
        }
    }

    .dropbox {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px dashed #48cfad;
        transition: background-color 0.3s;

        &--active {
            background-color: #ccfff2;
        }
    }
    .file {
        &Form {
            display: flex;
            flex-direction: column;
        }
        &Label {
            margin-bottom: 16px;
            display: inline-block;
            color: #37bc9b;
            cursor: pointer;
            text-align: center;
        }
        &Input {
            display: none;
        }
        &Description {
            display: inline-block;
            text-align: center;
        }
        &List {
            margin: 0;
            padding: 0 0 16px 0;
            list-style: none;

            li {
                padding: 4px 0;
            }
        }
        &Button {
            margin-right: 8px;
            background-color: transparent;
            border: none;
            border-radius: 50%;
            color: #da4453;
            cursor: pointer;
        }
        &Submit {
            background-color: #37bc9b;
            border: none;
            padding: 8px 16px;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .btn-option {
        font-size: 13px;
    }
}
</style>
