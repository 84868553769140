<template>
    <div>
        <div class="detail-report-main-header">
            <div class="detail-report-title">{{ $t('Routemap.HeatmapAnalysis') }}</div>
            <div class="md-layout" style="margin-top:15px">
                <div class="md-layout-item md-size-20">
                    <md-field>
                        <label>{{ $t('Routemap.SelectStore') }}</label>
                        <md-select v-model="selectListGroup">
                            <md-option
                                v-for="group in listGroup"
                                v-bind:key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="search-container choose-time md-layout-item md-size-20" style="margin-left:10px">
                    <v-date-picker v-model="range" is-range :locale="lang">
                        <template v-slot="{ inputEvents }">
                            <div class="flex justify-center items-center">
                                <input
                                    :value="displayDateRange()"
                                    v-on="inputEvents.start"
                                    class="base-input"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                        <img class="ml-16" src="/img/calendar.png" />
                </div>
                <div class="md-layout-item md-size-10" style="padding-top: 2%; padding-left: 3%" v-if="lang=='vi'"> 
                    {{ $t('Routemap.ComparedWith') }}
                </div>
                <div class="md-layout-item md-size-15" style="padding-top: 2%" v-else>
                    {{ $t('Routemap.ComparedWith') }}
                </div>
                <div class="search-container choose-time md-layout-item md-size-20">
                    <v-date-picker v-model="rangeCompare" is-range :locale="lang">
                        <template v-slot="{ inputEvents }">
                            <div class="flex justify-center items-center">
                                <input
                                    :value="displayDateRangeCompare()"
                                    v-on="inputEvents.start"
                                    class="base-input"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                        <img class="ml-16" src="/img/calendar.png" />
                </div>
            </div>
            <div class="main-content-select-date" style="margin-top:15px">
                <div class="select-date-main-title" style="margin-left:1%">
                    {{ $t('Routemap.FootfallHeatmapReportFrom') }} <b>{{ dateStart }}</b> {{ $t('Routemap.To') }} <b>{{ dateEnd }}</b>
                    {{ $t('Routemap.ComparedWith') }}
                    <b>{{ dateStartCompare }}</b> {{ $t('Routemap.To') }} <b>{{ dateEndCompare }}</b>
                </div>
            </div>
            <div class="md-layout" style="padding-top: 3%">
                <div class="md-layout-item md-small-size-100 md-size-50">
                    <RoutemapDashboard
                        :listCameraID="listCameraID"
                        :dateRange="rangeTimestamp"
                        :isCompare="true"
                    >
                        
                    </RoutemapDashboard>
                </div>
                <div class="md-layout-item md-small-size-100 md-size-50">
                    <RoutemapDashboard
                        :listCameraID="listCameraID"
                        :dateRange="rangeCompareTimestamp"
                        :isCompare="isCompare"
                    >
                    </RoutemapDashboard>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import RoutemapDashboard from "@/components/RoutemapDashboard";
import {Api} from "@/api";

const getListCameraModuleRouteMap = Api.get("getListCameraModuleRouteMap");

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");
export default {
    components: {
        RoutemapDashboard
    },
    mounted() {
        this.lang = localStorage.lang === "vn" ? "vi" : "en"
        this.range = {
            start: moment().startOf("day"),
            end: moment().startOf("day"),
        },
        
        this.rangeCompare = {
            start: moment().startOf("day"),
            end: moment().startOf("day"),
        }
        this.dateStart = this.getFullDate(this.range.start)
        this.dateEnd = this.getFullDate(this.range.end)
        this.dateStartCompare = this.getFullDate(this.rangeCompare.start)
        this.dateEndCompare = this.getFullDate(this.rangeCompare.end)
        this.$forceUpdate()
        this.getListCameraID("")
    },
    props: {
        listGroup: {
            type: Array,
        }
    },
    watch: {
        rangeCompare(val) {
            var startCompare = moment(val.start).startOf("day").format("x") / 1000;
            var endCompare = moment(val.end).startOf("day").format("x") / 1000;
            
            var start = moment(this.range.start).startOf("day").format("x") / 1000;
            var end = moment(this.range.end).startOf("day").format("x") / 1000;

            var rangeBeetweenDays = end - start 
            var rangeBeetweenDaysCompare = endCompare - startCompare
            
            if (rangeBeetweenDays !== rangeBeetweenDaysCompare) {
                this.$notify({
                    timeout: 2500,
                    message: this.$t("Heatmap.NotEqualTimeRange"),
                    icon: "add_alert",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "primary",
                });
            } else {
                this.rangeCompareTimestamp = {
                    start: moment(val.start).startOf("day").unix(),
                    end: moment(val.end).startOf("day").unix()
                };

                if (this.rangeTimestamp.start !== start || this.rangeTimestamp.end !== end) {
                    this.rangeTimestamp = {
                        start: start,
                        end: end
                    }
                }
                
                this.dateStart = this.getFullDate(this.range.start)
                this.dateEnd = this.getFullDate(this.range.end)
                this.dateStartCompare = this.getFullDate(val.start)
                this.dateEndCompare = this.getFullDate(val.end)
            }
            
        },
        range(val) {
            var start = moment(val.start).startOf("day").format("x") / 1000;
            var end = moment(val.end).startOf("day").format("x") / 1000;
            var startCompare = moment(this.rangeCompare.start).startOf("day").format("x") / 1000;
            var endCompare = moment(this.rangeCompare.end).startOf("day").format("x") / 1000;

            var rangeBeetweenDays = end - start 
            var rangeBeetweenDaysCompare = endCompare - startCompare
            
            if (rangeBeetweenDays !== rangeBeetweenDaysCompare) {
                this.$notify({
                    timeout: 2500,
                    message: this.$t("Heatmap.NotEqualTimeRange"),
                    icon: "add_alert",
                    horizontalAlign: "right",
                    verticalAlign: "top",
                    type: "primary",
                });
            } else {
                this.rangeTimestamp = {
                    start: moment(val.start).startOf("day").unix(),
                    end: moment(val.end).startOf("day").unix()
                };

                if (this.rangeTimestampCompare.start !== startCompare || this.rangeTimestampCompare.end !== endCompare) {
                    this.rangeTimestampCompare = {
                        start: startCompare,
                        end: endCompare
                    }
                }
                
                this.dateStart = this.getFullDate(val.start)
                this.dateEnd = this.getFullDate(val.end)
                this.dateStartCompare = this.getFullDate(this.rangeCompare.start)
                this.dateEndCompare = this.getFullDate(this.rangeCompare.end)
            }
        },
        selectListGroup(val) {
            for (let i = 0; i < this.listGroup.length; i++) {
                if (this.listGroup[i].id === val) {
                    this.selectGroupName = this.listGroup[i].name;
                }
            }
            this.getListCameraID(val)
        },

    },
    data() {
        return {
            isCompare: true,
            lang: "vn",
            range: {
                start: moment().startOf("day"),
                end: moment().startOf("day"),
            },
            rangeTimestamp: {
                start: 0,
                end: 0
            },
            rangeCompare: {
                start: moment().startOf("day"),
                end: moment().startOf("day"),
            },
            rangeCompareTimestamp: {
                start: 0,
                end: 0
            },

            selectListGroup: "",
            selectGroupName: "",

            dateStart: "",
            dateEnd: "",
            dateStartCompare: "",
            dateEndCompare: "",
            listCameraID: []
        }
    },
    methods: {
        displayDateRange() {
            
            var string =
                moment(this.range.start).locale("vi").format("DD/MM") +
                " - " +
                moment(this.range.end).locale("vi").format("DD/MM");
            return string;
        },
        displayDateRangeCompare() {
            var string =
                moment(this.rangeCompare.start).locale("vi").format("DD/MM") +
                " - " +
                moment(this.rangeCompare.end).locale("vi").format("DD/MM");
            return string;
        },
        getFullDate(val) {
            try {
                var date = val._d.getDate()
                if (date < 10) {
                    date = "0" + date.toString()
                }
                var month = val._d.getMonth() + 1
                if (month < 10) {
                    month = "0" + month.toString()
                }
                var year = val._d.getFullYear()
                var dateString = date + "/" + month + "/" + year
                return dateString
            } catch(error) {
                var date = val.getDate()
                if (date < 10) {
                    date = "0" + date.toString()
                }
                var month = val.getMonth() + 1
                if (month < 10) {
                    month = "0" + month.toString()
                }
                var year = val.getFullYear()
                var dateString = date + "/" + month + "/" + year
                return dateString
            }
            
        },
        getListCameraID(groupID) {
            let self = this;
            getListCameraModuleRouteMap.get(groupID).then(response => {
                self.listCameraID = response.data.data
            })
        },
    }
    
}
</script>

<style lang="scss">
.detail-report-main-container {

    .detail-select-container {
        display: flex;
        .select-area {
            width: 20%;
            margin-left: auto;
            margin-top: 0;
        }
    }

    .note-conversion-rate {
        padding-left: 25px;
        h4 {
            margin: 0;
            color: #ff6459;
            font-weight: bold;
        }

        ul > li {
            font-size: 15px;
            color: #7f7f7f;
            margin: 20px 0 0 40px;
        }
    }

}

@media only screen and (max-width: 1280px) {
    .detail-report-main-container {
        gap: 30px;

        .note-conversion-rate {
            ul > li {
                margin: 13px 0 0 30px;
            }
        }
    }
}
</style>