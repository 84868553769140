<template>
    <div class="route-map-dashboard">
        <div class="route-map-container">
            <div
                class="route-map"
                style="
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                "
            >   
                <span
                    class="skeleton-box"
                    v-show="isLoading"
                    :style="loadingStyle"

                    ref="loadingImage"
                ></span>
                <v-stage
                    v-show="!isLoading"
                    v-if="isLoadImageDone"
                    :config="configKonva"
                    class="stage"
                    :style="stageHeight"
                >
                    <v-layer ref="layer">
                        <v-image
                            style="border-radius: 15px"
                            :config="{
                                        image: image,
                                        width: configKonva.width,
                                        height: imageHeight,
                                    }"
                        />
                        <v-arrow
                            v-for="arrow in routes"
                            :key="arrow.id"
                            :config="{
                                        x: 0,
                                        y: 0,
                                        tension: 0.2,
                                        points: getWayOfArrow(arrow),
                                        pointerLength: 6,
                                        pointerWidth: 6,
                                        fill: 'White',
                                        shadowColor: arrow['color'],
                                        shadowBlur: 0,
                                        stroke: arrow['color'],
                                        lineCap: 'round',
                                        opacity: 0.9,
                                        strokeWidth:8,
                                        scale:4,
                                    }"
                        >
                        </v-arrow>
                        <v-text
                            v-for="(percentage, index) in percentages"
                            :key="index"
                            :config="getText(percentage)"
                        >
                        </v-text>
                    </v-layer>
                </v-stage>
            </div>
            <button
                class="prev navigate-btn "
                v-bind:class="[isCompare ? 'top-small' : 'top-big']"
                @click="prev"
            >&#10094;
            </button>
            <button
                class="next navigate-btn "
                v-bind:class="[isCompare ? 'top-small' : 'top-big']"
                @click="next"
            >&#10095;
            </button>
        </div>
    </div>
</template>

<script>

import {Api} from "@/api/index";

const getReportRoutemapByCameraAndDate = Api.get("listRouteV2");
const height = 550;
const width = (window.innerWidth / 100) * 40;

export default {
    name: "RoutemapDashboard",
    props: {
        listCameraID: {
            type: Array
        },
        dateRange: {
            type: Object
        },
        isCompare: {
            type: Boolean,
        },
    },
    mounted() {
        if (this.isCompare) {

            this.configKonva = {
                width: 380,
                height: 300
            }
            this.imageHeight = 350
        }

    },
    data() {
        return {
            currentIndex: 0,
            image: "",
            isLoading: false,
            configKonva: {
                width: width,
                height: height,
            },
            routes: [],
            percentages: [],
            isLoadImageDone: false,
            isFirstLoad: true,
            imageHeight: 550,
        }
    },
    computed: {
        loadingStyle() {
            if (this.isCompare) {
                return {
                    "width": `${this.configKonva.width}px`,
                    "border-radius": "15px",
                    "height": "300px"
                }
            }
            return {
                "width": `${this.configKonva.width}px`,
                "border-radius": "15px",
                "height": "550px"
            }
        },
        stageHeight() {
            if (this.isCompare) {
                return {"height": "350px"}
            }

            return {"height": "550px"}
        }
    },
    watch: {
        listCameraID(listCameraID) {
            this.currentIndex = 0
            this.processListCameraID(listCameraID, this.dateRange.start, this.dateRange.end)
        },
        dateRange(dateRange) {
            if (!this.isFirstLoad) {
                this.currentIndex = 0
                this.processListCameraID(this.listCameraID, dateRange.start, dateRange.end)
            }
        }
    },
    methods: {
        processListCameraID(listCameraID, dateStart, dateEnd) {
            this.isLoading = true

            let cameraID = listCameraID[this.currentIndex]

            this.getRouteMapData(cameraID, dateStart, dateEnd)
            this.isFirstLoad = false
        },
        getRouteMapData(cameraID, dateStart, dateEnd) {
            let self = this
            getReportRoutemapByCameraAndDate.get(dateStart, dateEnd, cameraID)
                .then(response => {
                    const listResponse = response.data["listResponse"]
                    if (listResponse !== null && listResponse.length > 0) {
                        self.routes = listResponse[0]["routes"]
                        self.percentages = listResponse[0]["percentage"]

                        let image = new Image();
                        image.src = listResponse[0]["image"]
                        image.onload = () => {
                            self.image = image
                            self.isLoadImageDone = true
                            self.isLoading = false
                        }
                    }
                })
        },
        next: function () {
            this.isLoading = true
            if (this.currentIndex === this.listCameraID.length - 1) {
                this.currentIndex = 0
            } else {
                this.currentIndex += 1
            }

            let cameraID = this.listCameraID[this.currentIndex]
            this.getRouteMapData(cameraID, this.dateRange.start, this.dateRange.end)
        },
        prev: function () {
            this.isLoading = true
            if (this.currentIndex === 0) {
                this.currentIndex = this.listCameraID.length - 1
            } else {
                this.currentIndex -= 1
            }

            let cameraID = this.listCameraID[this.currentIndex]
            this.getRouteMapData(cameraID, this.dateRange.start, this.dateRange.end)
        },
        getText(percentage) {
            let fontSize = 15
            
            if (!this.isCompare) {
                fontSize = 19
            }
            
            let x = percentage.x * this.configKonva.width + fontSize / 2;
            let y = percentage.y * this.configKonva.height + fontSize / 2;
            if (this.isCompare) {
                x = percentage.x * this.configKonva.width;
                y = percentage.y * this.configKonva.height;
            }
            return {
                x: x,
                y: y,
                text: percentage.text,
                fill: 'white',
                fontSize: fontSize,
                fontStyle: 'bold',
                points: [50, 140, 250, 160],
                shadowColor: 'black',
                shadowBlur: 1,
                shadowOffset: { x: 1.5, y: 1 },
                shadowOpacity: 1,
            };
        },
        getWayOfArrow(arrow) {
            if (arrow.start[1] > 0.85) {
                arrow.start[1] = arrow.start[1] - 0.01
            }

            if (arrow.end[1] > 0.85) {
                arrow.end[1] = arrow.end[1] - 0.01
            }
            if (arrow["mid"].length !== 0) {
                if (arrow.mid[1] > 0.85) {
                    arrow.mid[1] = arrow.mid[1] - 0.01
                }
                return [
                    arrow.start[0] * this.configKonva.width,
                    arrow.start[1] * this.configKonva.height,
                    arrow.mid[0] * this.configKonva.width,
                    arrow.mid[1] * this.configKonva.height,
                    arrow.end[0] * this.configKonva.width,
                    arrow.end[1] * this.configKonva.height,
                ];
            } else {
                return [
                    arrow.start[0] * this.configKonva.width,
                    arrow.start[1] * this.configKonva.height,
                    arrow.end[0] * this.configKonva.width,
                    arrow.end[1] * this.configKonva.height,
                ];
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.route-map-dashboard {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
}

.route-map-container {
    position: relative;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    
    text-decoration: none;
    user-select: none;
}

.next {
    right: -45px;
    border-radius: 0 4px 4px 0;
}

.prev {
    left: -45px;
    border-radius: 4px 0 0 4px;
}

.prev:hover, .next:hover {
    background-color: #474747;
    color: #ffffff;
}

.navigate-btn {
    color: black;
    border: none;
    background-color: #c8c8c8;
    position: absolute;
}

.top-big {
    top: 245px; 
}

.top-small {
    top: 110px;
}

.route-map {
    canvas {
        border-radius: 15px;
    }
}

.stage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 15px;
}

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
</style>