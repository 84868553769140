<template>
    <div id="Overview" class="w3-container city">
        <div class="wrapTableBalance">
            <div class="wrapSelect">
                <div class="wrapStore">
                    <span class="titleButton">{{
                        $t("Report.StoreChoosing")
                    }}</span>
                    <div class="custom-button-menu" @mouseover="hoverChoosing" @mouseleave="hoverChoosingOut">
                        {{ groupName }}
                        <span class="material-icons"> arrow_drop_down </span>
                        <div ref="wrapOption" class="wrapOption">
                            <div
                                class="Option"
                                v-for="item in listGroup"
                                :key="item.id"
                                @click="changeGroup(item.id, item.name)"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wrapTimpicker">
                    <span class="titleButton">{{
                        $t("Report.ShowMetricsBy")
                    }}</span>
                    <TimeSelectTabReport
                        @changeDateRange="changeDateRange"
                        @changeDateRangeString="changeDateRangeString"
                        @changeCompareType="changeCompareType"
                        @changeSelectType="changeSelectType"
                    >
                    </TimeSelectTabReport>
                    <!-- TimePicker -->
                </div>
                <div class="wrapStore">
                    <span class="titleButton">{{ $t("Report.In") }}</span>
                    <div class="custom-button-menu" @mouseover="hoverChoosingStore" @mouseleave="hoverChoosingOutStore">
                        {{ previousRepeat }} {{ collumnText }}
                        <span class="material-icons">
                            arrow_drop_down
                        </span>
                        <div ref="wrapOptionStore" class="wrapOption">
                            <div class="Option" @click="changePrevious('4')">
                                4 {{ $t("Report.Columns") }}
                            </div>
                            <div class="Option" @click="changePrevious('7')">
                                7 {{ $t("Report.Columns") }}
                            </div>
                            <div class="Option" @click="changePrevious('12')">
                                12 {{ $t("Report.Columns") }}
                            </div>
                            <div class="Option" @click="changePrevious('15')">
                                15 {{ $t("Report.Columns") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Balance -->
            <md-table class="TableCustom" :value="dataForTable1">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell :md-label="$t('Report.StandardMetrics')">{{
                        item.atributeName
                    }}</md-table-cell>
                    <md-table-cell :md-label="$t('Report.Trend')" style="cursor: pointer">
                        <sparkline
                            :indicatorStyles="spIndicatorStyles1"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineCurve
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStylesLine"
                            />
                        </sparkline>
                    </md-table-cell>
                    <md-table-cell :md-label="listDateRange[0]">{{
                        item.value0
                    }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[1]">{{
                        item.value1
                    }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[2]">{{
                        item.value2
                    }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[3]">{{
                        item.value3
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="listDateRange[4]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value4 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[5]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value5 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[6]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value6 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[7]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value7 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[8]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value8 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[9]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value9 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[10]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value10 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[11]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value11 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[12]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value12 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[13]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value13 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[14]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value14 }}</md-table-cell
                    >
                </md-table-row>
            </md-table>
            <md-table class="TableCustom2" v-if="genderPercent == false">
                <md-table-row>
                    <md-table-head>{{
                        $t("Report.VisitorDemographic")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '4'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '7'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '12'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(item, index) in dataForTable2"
                    :key="index"
                    :md-item="row"
                >
                    <md-table-cell md-numeric>{{
                        item.atributeName
                    }}</md-table-cell>
                    <md-table-cell style="cursor: pointer">
                        <sparkline
                            v-if="dataForTable2.indexOf(item) == 0"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles1"
                            />
                        </sparkline>
                        <sparkline
                            v-if="dataForTable2.indexOf(item) == 1"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles2"
                            />
                        </sparkline>
                    </md-table-cell>
                    <md-table-cell>{{ item.value0 }}</md-table-cell>
                    <md-table-cell>{{ item.value1 }}</md-table-cell>
                    <md-table-cell>{{ item.value2 }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[3]">{{
                        item.value3
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="listDateRange[4]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value4 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[5]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value5 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[6]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value6 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[7]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value7 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[8]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value8 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[9]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value9 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[10]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value10 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[11]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value11 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[12]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value12 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[13]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value13 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[14]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value14 }}</md-table-cell
                    >
                </md-table-row>
            </md-table>

            <md-table class="TableCustom2" v-if="genderPercent == true">
                <md-table-row>
                    <md-table-head>{{
                        $t("Report.VisitorDemographic")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head>{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '4'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '7'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ $t("dashboard.Demographics") }}</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '12'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">{{
                        $t("dashboard.Demographics")
                    }}</md-table-head>
                    <md-table-head v-if="previousRepeat == '15'">
                        <md-switch v-model="genderPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(item, index) in dataForPercentage2"
                    :key="index"
                    :md-item="row"
                >
                    <md-table-cell md-numeric>{{
                        item.atributeName
                    }}</md-table-cell>
                    <md-table-cell style="cursor: pointer">
                        <sparkline
                            v-if="dataForPercentage2.indexOf(item) == 0"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles1"
                            />
                        </sparkline>
                        <sparkline
                            v-if="dataForPercentage2.indexOf(item) == 1"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles2"
                            />
                        </sparkline>
                    </md-table-cell>
                    <md-table-cell>{{ item.value0 }}</md-table-cell>
                    <md-table-cell>{{ item.value1 }}</md-table-cell>
                    <md-table-cell>{{ item.value2 }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[3]">{{
                        item.value3
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="listDateRange[4]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value4 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[5]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value5 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[6]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value6 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[7]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value7 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[8]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value8 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[9]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value9 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[10]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value10 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[11]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value11 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[12]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value12 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[13]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value13 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[14]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value14 }}</md-table-cell
                    >
                </md-table-row>
            </md-table>

            <md-table class="TableCustom2" v-if="weekPercent == false">
                <md-table-row>
                    <md-table-head>{{
                        $t("Report.VisitorsMetric")
                    }}</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head v-if="previousRepeat == '4'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '7'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '12'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(item, index) in dataForTable3"
                    :key="index"
                    :md-item="row"
                >
                    <md-table-cell md-numeric>{{
                        item.atributeName
                    }}</md-table-cell>
                    <md-table-cell style="cursor: pointer">
                        <sparkline
                            v-if="dataForTable3.indexOf(item) == 0"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles3"
                            />
                        </sparkline>
                        <sparkline
                            v-if="dataForTable3.indexOf(item) == 1"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles4"
                            />
                        </sparkline>
                    </md-table-cell>
                    <md-table-cell>{{ item.value0 }}</md-table-cell>
                    <md-table-cell>{{ item.value1 }}</md-table-cell>
                    <md-table-cell>{{ item.value2 }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[3]">{{
                        item.value3
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="listDateRange[4]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value4 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[5]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value5 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[6]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value6 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[7]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value7 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[8]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value8 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[9]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value9 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[10]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value10 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[11]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value11 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[12]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value12 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[13]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value13 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[14]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value14 }}</md-table-cell
                    >
                </md-table-row>
            </md-table>
            <md-table class="TableCustom2" v-if="weekPercent == true">
                <md-table-row>
                    <md-table-head>{{
                        $t("Report.VisitorsMetric")
                    }}</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head>Nhân khẩu học</md-table-head>
                    <md-table-head v-if="previousRepeat == '4'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '7'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '12'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'"
                        >Nhân khẩu học</md-table-head
                    >
                    <md-table-head v-if="previousRepeat == '15'">
                        <md-switch v-model="weekPercent">{{
                            $t("Report.ShowPercentage")
                        }}</md-switch>
                    </md-table-head>
                </md-table-row>

                <md-table-row
                    v-for="(item, index) in dataForPercentage3"
                    :key="index"
                    :md-item="row"
                >
                    <md-table-cell md-numeric>{{
                        item.atributeName
                    }}</md-table-cell>
                    <md-table-cell>
                        <sparkline
                            v-if="dataForPercentage3.indexOf(item) == 0"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles3"
                            />
                        </sparkline>
                        <sparkline
                            v-if="dataForPercentage3.indexOf(item) == 1"
                            width="100"
                            :tooltipProps="spTooltipPropsTable"
                            @click.native="showChart(item)"
                        >
                            <sparklineBar
                                :margin="spMargin4"
                                :data="item.chartData"
                                :limit="item.chartData.length"
                                :styles="spCurveStyles4"
                            />
                        </sparkline>
                    </md-table-cell>
                    <md-table-cell>{{ item.value0 }}</md-table-cell>
                    <md-table-cell>{{ item.value1 }}</md-table-cell>
                    <md-table-cell>{{ item.value2 }}</md-table-cell>
                    <md-table-cell :md-label="listDateRange[3]">{{
                        item.value3
                    }}</md-table-cell>
                    <md-table-cell
                        :md-label="listDateRange[4]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value4 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[5]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value5 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[6]"
                        v-if="
                            previousRepeat == '7' ||
                                previousRepeat == '12' ||
                                previousRepeat == '15'
                        "
                        >{{ item.value6 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[7]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value7 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[8]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value8 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[9]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value9 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[10]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value10 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[11]"
                        v-if="previousRepeat == '12' || previousRepeat == '15'"
                        >{{ item.value11 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[12]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value12 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[13]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value13 }}</md-table-cell
                    >
                    <md-table-cell
                        :md-label="listDateRange[14]"
                        v-if="previousRepeat == '15'"
                        >{{ item.value14 }}</md-table-cell
                    >
                </md-table-row>
            </md-table>
        </div>
        <div class="wrapTableBalance1">
            <div class="wrapSelect">
                <TimeSelectTabReport
                    @changeDateRange="changeDateRangeTree"
                    @changeDateRangeString="changeDateRangeStringTree"
                >
                </TimeSelectTabReport>
                <!-- TimePicker -->
                <div class="custom-button" style="cursor: pointer" @mouseover="hoverChoosingTreemap" @mouseleave="hoverChoosingOutTreemap">
                    {{ attributeName }}
                    <span class="material-icons"> arrow_drop_down </span>
                    <div ref="wrapOptionTreemap" class="wrapOption">
                        <div
                            class="Option"
                            @click="changeAttribute('comeIn', $t('Report.NumberOfVisitor'))"
                        >
                            {{ $t("Report.NumberOfVisitor") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeAttribute('shop', $t('Report.NumberOfBuyer'))"
                        >
                            {{ $t("Report.NumberOfBuyer") }}
                        </div>
                        <div
                            class="Option"
                            @click="changeAttribute('way', $t('Report.NumberOfPassby'))"
                        >
                            {{ $t("Report.NumberOfPassby") }}
                        </div>
                    </div>
                </div>

                <!-- done select time & TimePicker -->
            </div>
            <div id="TreeMap">
                <apexchart
                    type="treemap"
                    height="350"
                    :options="chartOptions"
                    :series="dataTreeMap"
                ></apexchart>
                <LineChart
                    :series="lineChartData"
                    :dateRange="listDateRange"
                    :dateFormat="dateFormat"
                ></LineChart>
            </div>
        </div>
        <modal height="auto" name="my-first-modal">
            <div class="HeadChart">
                <div class="lineColor"></div>
                <span class="Sublinetext">{{ titleInfoCardChart }}</span>
            </div>
            <apexchart
                type="line"
                height="250"
                :options="chartOptionsModal"
                :series="seriesChartModal"
            ></apexchart>
        </modal>
    </div>
    <!-- Tab Chiến dịch -->
</template>
<script>
import { Api } from "@/api/index";
import * as moment from "moment-timezone";
import { TimeSelectTabReport } from "@/pages"

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getListGroup = Api.get("getListGroupV2");
const compareOverall = Api.get("compareOverall");
const compareAllShop = Api.get("compareAllShop");
export default {
    name: "BalanceTabReport",
    components: {
        TimeSelectTabReport
    },
    mounted() {
        getListGroup.get().then(response => {
            var listGroup = response.data.listResponse;
            listGroup.unshift({ id: "", name: this.allShop });
            this.listGroup = listGroup;
        });
        this.getDataCompareOverall(
            this.dateRange.dateStart,
            this.dateRange.dateEnd,
            this.compareType,
            this.group,
            this.previousRepeat,
            "month"
        );
        this.getDataAllShop(
            this.dateRangeTree.dateStart,
            this.dateRangeTree.dateEnd,
            this.attributeType,
            this.resultType
        );
    },
    data() {
        var self = this
        return {
            currentTime: moment().format("HH:mm"),
            lang: localStorage.lang=="vn" ? "vi" : "en",

            genderPercent: false,
            weekPercent: false,
            attributeType: "comeIn",
            attributeName: self.$t("Report.NumberOfPassby"),
            resultType: "total",
            dataForTable1: [],
            dataForTable2: [],
            dataForTable3: [],
            dataForPercentage2: [],
            dataForPercentage3: [],
            selectType: "month",

            dataFromCompareOverall: [],
            previousRepeat: "4",
            dateRange: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            },
            dateRangeString: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            listGroup: [],
            compareType: "toNow",
            group: "",
            groupName: self.$t("dashboard.All"),
            spMargin4: 8,
            spCurveStylesLine: {
                stroke: "#276EFF"
            },
            spCurveStyles1: {
                fill: "#0063B1"
            },
            spCurveStyles2: {
                fill: "#FFB900"
            },
            spCurveStyles3: {
                fill: "#276EFF"
            },
            spCurveStyles4: {
                fill: "#10893E"
            },

            dataTreeMap: [
                {
                    data: []
                }
            ],
            chartOptions: {
                colors: ["#0063B1"],
                legend: {
                    show: false
                },
                tooltip: {
                    enabled: true,
                    tooltip: {
                        shared: true,
                        intersect: false,
                        followCursor: true
                    },
                    style: {
                        enabled: true,
                        fontSize: "12px",
                        fontFamily: "Roboto"
                    }
                },
                chart: {
                    height: 350,
                    type: "treemap"
                }
            },
            

            dateRangeTree: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("X"),
                dateEnd: moment()
                    .endOf("month")
                    .startOf("day")
                    .format("X")
            },
            dateRangeStringTree: {
                dateStart: moment()
                    .startOf("month")
                    .startOf("day")
                    .format("DD/MM/YYYY"),
                dateEnd: moment()
                    .startOf("day")
                    .format("DD/MM/YYYY")
            },
            dateRangeStringHoverTree: {
                dateStart: "",
                dateEnd: ""
            },

            spTooltipPropsTable: {
                formatter(val) {
                    return `alo:<label style="font-weight:bold;">${val.value}</label>`;
                }
            },
            seriesChartModal: [],
            chartOptionsModal: {},
            titleInfoCardChart: "",
            collumnText: self.$t("Report.CollumnText"),
            allShop: self.$t("dashboard.All"),

            dayText: self.$t("Report.DayText"),
            monthText: self.$t("Report.MonthText"),
            yearText: self.$t("Report.YearText"),
            quarterText: self.$t("Report.QuarterText"),
            numberOfPassby: self.$t("Report.NumberOfPassby"),
            numberOfVisitor: self.$t("Report.NumberOfVisitor"),
            numberOfBuyer: self.$t("Report.NumberOfBuyer"),
            TurnInRate: self.$t("dashboard.TurnInRate"),
            conversionRate: self.$t("dashboard.SaleConversionRate"),
            maleText: self.$t("dashboard.Male"),
            femaleText: self.$t("dashboard.Female"),
            weekdayText: self.$t("Report.Weekdays"),
            weekendText: self.$t("Report.Weekends"),
            timeAtCounter: self.$t("Report.TimeAtCounter"),

            listMonth: self.$t("Report.ListMonth")
        }
    },
    methods: {
        hoverChoosing() {
            this.$refs["wrapOption"].style.display = "block";
            this.$refs["wrapOption"].style.visibility = "visible";
        },
        hoverChoosingOut() {
            this.$refs["wrapOption"].style.display = "none";
            this.$refs["wrapOption"].style.visibility = "hidden";
        },
        hoverChoosingStore() {
            this.$refs["wrapOptionStore"].style.display = "block";
            this.$refs["wrapOptionStore"].style.visibility = "visible";
        },
        hoverChoosingOutStore() {
            this.$refs["wrapOptionStore"].style.display = "none";
            this.$refs["wrapOptionStore"].style.visibility = "hidden";
        },
        hoverChoosingTreemap() {
            this.$refs["wrapOptionTreemap"].style.display = "block";
            this.$refs["wrapOptionTreemap"].style.visibility = "visible";
        },
        hoverChoosingOutTreemap() {
            this.$refs["wrapOptionTreemap"].style.display = "none";
            this.$refs["wrapOptionTreemap"].style.visibility = "hidden";
        },
        changeDateRange(val) {
            this.dateRange = val
        },
        changeDateRangeString(val) {
            this.dateRangeString = val
        },
        changeCompareType(val) {
            this.getDataCompareOverall(
                this.dateRange.dateStart,
                this.dateRange.dateEnd,
                val,
                this.group,
                this.previousRepeat,
                this.selectType
            );
            this.compareType = val
        },
        changeSelectType(val) {
            this.selectType = val
            this.getDataCompareOverall(
                this.dateRange.dateStart,
                this.dateRange.dateEnd,
                this.compareType,
                this.group,
                this.previousRepeat,
                val
            );
            
        },

        changeDateRangeTree(val) {
            this.dateRangeTree = val
            this.getDataAllShop(
                this.dateRangeTree.dateStart,
                this.dateRangeTree.dateEnd,
                this.attributeType,
                this.resultType
            );
        },
        changeDateRangeStringTree(val) {
            this.dateRangeStringTree = val
        },



        showChart(item, index) {
            this.seriesChartModal = [
                {
                    name: item.atributeName,
                    data: item.chartData
                }
            ];
            var tooltipUnit = "";
            this.titleInfoCardChart = item.atributeName;
            if (item.atributeName.includes("Tỉ lệ") == true || item.atributeName.includes("rate") == true) {
                tooltipUnit = "%";
            } else if (item.atributeName.includes("Thời gian") == true || item.atributeName.includes("time") == true) {
                tooltipUnit = localStorage.lang == "vn" ? " giây" : " second";
            } else {
                tooltipUnit = localStorage.lang == "vn" ? " người" : " people";
            }
            var dateFormat = [...this.listDateRange];
            this.chartOptionsModal = {
                chart: {
                    height: 250,
                    type: "line",
                    zoom: {
                        enabled: false
                    }
                },
                yaxis: {
                    min: 0,
                    tickAmount: 5
                },
                stroke: {
                    curve: "smooth"
                },
                title: {
                    text: "Product Trends by Month",
                    align: "left"
                },
                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: function(
                            value,
                            { series, seriesIndex, dataPointIndex, w }
                        ) {
                            return value + tooltipUnit;
                        }
                    }
                },
                xaxis: {
                    categories: dateFormat.reverse()
                },
                yaxis: {
                    labels: {
                        formatter: function(
                            value
                        ) {
                            return value + tooltipUnit;
                        }
                    }
                }
            };
            this.$modal.show("my-first-modal");
        },
        changeAttribute(id, name) {
            this.attributeType = id;
            this.attributeName = name;
        },
        changeGroup(id, name) {
            this.group = id;
            this.groupName = name;
        },
        changePrevious(id) {
            this.previousRepeat = id;
        },
        getDataAllShop(dateStart, dateEnd, attributeType, resultType) {
            this.dataTreeMap = [];
            compareAllShop
                .get(dateStart, dateEnd, attributeType, resultType)
                .then(response => {
                    var dataTree = {
                        data: []
                    };
                    for (let i = 0; i < response.data.data.length; i++) {
                        if (response.data.data[i].y != 0) {
                            dataTree.data.push(response.data.data[i]);
                        }
                    }
                    this.dataTreeMap.push(dataTree);
                });
        },
        getDataCompareOverall(
            dateStart,
            dateEnd,
            compareType,
            group,
            previousRepeat,
            selectType
        ) {
            var deltaTimestamp = dateEnd - dateStart;
            var dayStarted = moment.unix(dateStart).format("DD/MM")
            var startOfQuarter = ["01/01", "01/04", "01/07", "01/10"]
            if (selectType != "month" && (selectType == "quarter" && startOfQuarter.includes(dayStarted) == false))  {
                deltaTimestamp = deltaTimestamp + 86399;
            }
            var dateStartToCompute = dateStart;
            this.listDateRange = [];
            
            var dayCountPeriod =
                (moment()
                    .startOf("day")
                    .format("X") -
                    moment
                        .unix(dateStartToCompute)
                        .startOf(selectType)
                        .format("X")) /
                    86400 +
                1;
            var getStarted = false
            if ((moment().format("DD") == 31) || (moment().format("DD") == 30) || (moment().format("DD") == 28 && moment().format("MM") == "02")) {
                getStarted = true
            }
            if (compareType == "toNow" && getStarted == true && selectType == "month") {
                if (deltaTimestamp / 86400 > 29) {
                    deltaTimestamp = deltaTimestamp - 86400 * 2
                }
            }
            if (selectType == "day") {
                deltaTimestamp = deltaTimestamp + 86400
            }
            for (var i = 0; i < previousRepeat; i++) {
                if (compareType != "toNow") {
                    dayCountPeriod =
                        (moment
                            .unix(dateStartToCompute)
                            .endOf(selectType)
                            .format("X") -
                            moment
                                .unix(dateStartToCompute)
                                .startOf(selectType)
                                .format("X") +
                            1) /
                        86400;
                }
                if (compareType == "toNow" && getStarted == true && selectType == "month") { 
                    dayCountPeriod =
                        (moment
                            .unix(dateStartToCompute)
                            .endOf(selectType)
                            .format("X") -
                            moment
                                .unix(dateStartToCompute)
                                .startOf(selectType)
                                .format("X") + 1) /
                        86400;
                }

                if (selectType == "day") {
                    this.listDateRange.push(
                        moment
                            .unix(dateStartToCompute)
                            .startOf(selectType)
                            .format("DD/MM")
                    );
                } else if (selectType == "week") {
                    this.listDateRange.push(
                        moment
                            .unix(dateStartToCompute)
                            .startOf(selectType)
                            .format("DD/MM") +
                            " - " +
                            moment
                                .unix(dateStartToCompute)
                                .endOf(selectType)
                                .format("DD/MM") +
                            " (" +
                            dayCountPeriod +
                            " " +
                            this.dayText +
                            ")"
                    );
                } else if (selectType == "month") {
                    var subtractedMonth = i+1
                    if (moment.unix(dateStart).startOf(selectType).format("M") - (i+1) < 0) {
                        this.listDateRange.push(
                            this.listMonth[
                                this.listMonth.length + (moment.unix(dateStart).startOf(selectType).format("M") - (i+1))
                            ] +
                                " (" +
                                dayCountPeriod +
                                " " +
                                this.dayText +
                                ")"
                        );
                    } else {
                        
                        if ((this.listMonth[
                                moment
                                    .unix(dateStart)
                                    .startOf(selectType)
                                    .format("M") - subtractedMonth
                            ] == "February" || this.listMonth[
                                moment
                                    .unix(dateStart)
                                    .startOf(selectType)
                                    .format("M") - subtractedMonth
                            ] == "Tháng 2") && compareType != "toNow") {
                            this.listDateRange.push(
                                this.listMonth[
                                    moment
                                        .unix(dateStart)
                                        .startOf(selectType)
                                        .format("M") - subtractedMonth
                                ] +
                                    " (28 " +
                                    this.dayText +
                                    ")"
                            );
                        } else if ((this.listMonth[
                                moment
                                    .unix(dateStart)
                                    .startOf(selectType)
                                    .format("M") - subtractedMonth
                            ] != "February" && this.listMonth[
                                moment
                                    .unix(dateStart)
                                    .startOf(selectType)
                                    .format("M") - subtractedMonth
                            ] != "Tháng 2")) {
                            this.listDateRange.push(
                                this.listMonth[
                                    moment
                                        .unix(dateStart)
                                        .startOf(selectType)
                                        .format("M") - subtractedMonth
                                ] +
                                    " (" +
                                    dayCountPeriod +
                                    " " +
                                    this.dayText +
                                    ")"
                            );
                        } else {
                            this.listDateRange.push(
                                this.listMonth[
                                    moment
                                        .unix(dateStart)
                                        .startOf(selectType)
                                        .format("M") - subtractedMonth
                                ] +
                                    " (" +
                                    dayCountPeriod +
                                    " " +
                                    this.dayText +
                                    ")"
                            );
                        }
                        
                    }
                    
                } else if (selectType == "quarter") {
                    if ((moment().format("DD/MM") == "31/03") || (moment().format("DD/MM") == "30/06") || (moment().format("DD/MM") == "30/09") && (moment().format("DD/MM") == "31/12")) {
                        if (moment.unix(dateStartToCompute).endOf('quarter').format("DD/MM") == "31/03") {
                            if (moment.unix(dateStartToCompute).startOf('quarter').isLeapYear()) {
                                dayCountPeriod = 91
                            } else {
                                dayCountPeriod = 90
                            }
                        } else if (moment.unix(dateStartToCompute).endOf('quarter').format("DD/MM") == "30/06") {
                            dayCountPeriod = 91
                        } else if (moment.unix(dateStartToCompute).endOf('quarter').format("DD/MM") == "30/09") {
                            dayCountPeriod = 92
                        } else if (moment.unix(dateStartToCompute).endOf('quarter').format("DD/MM") == "31/12") {
                            dayCountPeriod = 92
                        }
                    } else {
                        var currentDate = moment().startOf('day').format('X');

                        var dateMinus = moment.unix(currentDate).subtract(i*3, 'months').format('X');
                        var dateMinusStartQuarter = moment.unix(dateMinus).startOf('quarter').format('X')
                         
                        dayCountPeriod = (dateMinus - dateMinusStartQuarter) / 86400 + 1
                        // if (dayCountPeriod == 0) {
                        //     dayCountPeriod = dayCountPeriod + 1
                        // }
                    }

                    this.listDateRange.push(
                        this.quarterText +
                            " " +
                            moment
                                .unix(dateStartToCompute)
                                .startOf(selectType)
                                .quarter() +
                            " (" +
                            dayCountPeriod +
                            " " +
                            this.dayText +
                            ")"
                    );
                    
                } else if (selectType == "year") {
                    var year = moment.unix(dateStartToCompute).startOf(selectType).year()
                    if ((year % 4===0 &&year%100 !==0 && year % 400 !==0)||(year%100===0 && year % 400===0)) {
                        var dayCountPeriodTime = dayCountPeriod
                        if (compareType == "toNow") {
                            dayCountPeriodTime = dayCountPeriodTime + 1
                        }
                        this.listDateRange.push(
                            this.yearText +
                                " " +
                                moment
                                    .unix(dateStartToCompute)
                                    .startOf(selectType)
                                    .year() +
                                " (" +
                                dayCountPeriodTime +
                                " " +
                                this.dayText +
                                ")"
                        );
                    } else {
                        this.listDateRange.push(
                            this.yearText +
                                " " +
                                moment
                                    .unix(dateStartToCompute)
                                    .startOf(selectType)
                                    .year() +
                                " (" +
                                dayCountPeriod +
                                " " +
                                this.dayText +
                                ")"
                        );
                    }
                    
                }
                dateStartToCompute = dateStartToCompute - deltaTimestamp;
                if (i == 11) {
                    dateStartToCompute = dateStartToCompute - 86400 * 3
                }
            }
            compareOverall
                .get(dateStart, dateEnd, compareType, group, previousRepeat)
                .then(response => {
                    this.dataForTable1 = [];
                    this.dataForTable2 = [];
                    this.dataForTable3 = [];
                    this.dataForPercentage2 = [];
                    this.dataForPercentage3 = [];

                    var gateData = {
                        atributeName: this.numberOfVisitor,
                        chartData: response.data.gate.reverse()
                    };
                    var wayData = {
                        atributeName: this.numberOfPassby,
                        chartData: response.data.way.reverse()
                    };
                    var shopData = {
                        atributeName: this.numberOfBuyer,
                        chartData: response.data.shop.reverse()
                    };
                    var listConversionRate1 = response.data.conversionRate1.reverse();
                    listConversionRate1 = listConversionRate1.map(function(
                        item
                    ) {
                        return item == 100000001 ? 0 : item;
                    });
                    var conversionRate1Data = {
                        atributeName: this.TurnInRate,
                        chartData: listConversionRate1
                    };

                    var listConversionRate2 = response.data.conversionRate2.reverse();
                    listConversionRate2 = listConversionRate2.map(function(
                        item
                    ) {
                        return item == 100000001 ? 0 : item;
                    });
                    var conversionRate2Data = {
                        atributeName: this.conversionRate,
                        chartData: listConversionRate2
                    };

                    var listTimeShop = [...response.data.timeInShop];
                    var count = response.data.gate.length - 1;
                    var listTimeSecond = [];
                    for (var i = 0; i < listTimeShop.length; i++) {
                        listTimeSecond.push(
                            parseInt(listTimeShop[count - i].split(" ")[0])
                        );
                    }
                    var timeInShop = {
                        atributeName: this.timeAtCounter,
                        chartData: listTimeSecond
                    };
                    
                    var maleData = {
                        atributeName: this.maleText,
                        chartData: response.data.male.reverse()
                    };
                    const chartDataFemale = [...response.data.female];
                    var femaleData = {
                        atributeName: this.femaleText,
                        chartData: chartDataFemale
                    };
                    var maleDataPercentage = {
                        atributeName: this.maleText,
                        chartData: response.data.male
                    };
                    var femaleDataPercentage = {
                        atributeName: this.femaleText,
                        chartData: response.data.female
                    };
                    var weekdayData = {
                        atributeName: this.weekdayText,
                        chartData: response.data.weekday.reverse()
                    };
                    const weekendDataChart = [...response.data.weekend];
                    response.data.weekend.reverse();
                    var weekendData = {
                        atributeName: this.weekendText,
                        chartData: weekendDataChart.reverse()
                    };
                    var weekdayDataPercentage = {
                        atributeName: this.weekdayText,
                        chartData: response.data.weekday
                    };
                    var weekendDataPercentage = {
                        atributeName: this.weekendText,
                        chartData: weekendDataChart
                    };

                    for (let i = 0; i < response.data.gate.length; i++) {
                        gateData["value" + i] = response.data.gate[count - i];
                        wayData["value" + i] = response.data.way[count - i];
                        shopData["value" + i] = response.data.shop[count - i];
                        var conversionRate1 =
                            response.data.conversionRate1[count - i];
                        if (conversionRate1 == 100000001) {
                            conversionRate1 = 0;
                        }
                        var conversionRate2 =
                            response.data.conversionRate2[count - i];
                        if (conversionRate2 == 100000001) {
                            conversionRate2 = 0;
                        }
                        conversionRate1Data["value" + i] =
                            conversionRate1 + "%";
                        conversionRate2Data["value" + i] =
                            conversionRate2 + "%";
                        timeInShop["value" + i] = response.data.timeInShop[i] + " " + this.$t("Report.Second").toLowerCase();
                        maleData["value" + i] = response.data.male[count - i];
                        femaleData["value" + i] =
                            response.data.female[count - i];

                        weekdayData["value" + i] =
                            response.data.weekday[count - i];
                        weekendData["value" + i] =
                            response.data.weekend[count - i];

                        var totalWeek =
                            response.data.weekday[count - i] +
                            response.data.weekend[count - i];
                        var totalGender =
                            maleData["value" + i] + femaleData["value" + i];

                        if (totalGender == 0) {
                            maleDataPercentage["value" + i] = 0 + "%";
                            femaleDataPercentage["value" + i] = 0 + "%";
                        } else if (
                            response.data.male[count - i] == 0 &&
                            response.data.female[count - i] != 0
                        ) {
                            maleDataPercentage["value" + i] = 0 + "%";
                            femaleDataPercentage["value" + i] = 100 + "%";
                        } else if (
                            response.data.female[count - i] == 0 &&
                            response.data.male[count - i] != 0
                        ) {
                            femaleDataPercentage["value" + i] = 0 + "%";
                            maleDataPercentage["value" + i] = 100 + "%";
                        } else {
                            maleDataPercentage["value" + i] =
                                parseFloat(
                                    (response.data.male[count - i] /
                                        totalGender) *
                                        100
                                ).toFixed(2) + "%";
                            femaleDataPercentage["value" + i] =
                                parseFloat(
                                    (femaleData["value" + i] / totalGender) *
                                        100
                                ).toFixed(2) + "%";
                        }

                        if (totalWeek == 0) {
                            weekdayDataPercentage["value" + i] = 0 + "%";
                            weekendDataPercentage["value" + i] = 0 + "%";
                        } else if (
                            response.data.weekday[count - i] == 0 &&
                            response.data.weekend[count - i] != 0
                        ) {
                            weekdayDataPercentage["value" + i] = 0 + "%";
                            weekendDataPercentage["value" + i] = 100 + "%";
                        } else if (
                            response.data.weekend[count - i] == 0 &&
                            response.data.weekday[count - i] != 0
                        ) {
                            weekendDataPercentage["value" + i] = 0 + "%";
                            weekdayDataPercentage["value" + i] = 100 + "%";
                        } else {
                            weekdayDataPercentage["value" + i] =
                                parseFloat(
                                    (weekdayData["value" + i] / totalWeek) * 100
                                ).toFixed(2) + "%";
                            weekendDataPercentage["value" + i] =
                                parseFloat(
                                    (weekendData["value" + i] / totalWeek) * 100
                                ).toFixed(2) + "%";
                        }
                    }
                    var self = this;
                    this.spTooltipPropsTable = {
                        formatter(val) {
                            var stringFormat = self.listDateRange[
                                self.listDateRange.length - 1 - val.index
                            ].split(" (")[0];
                            return `${stringFormat}: <label style="font-weight:bold;">${val.value}</label>`;
                        }
                    };

                    this.dataForTable1.push(wayData);
                    this.dataForTable1.push(gateData);
                    this.dataForTable1.push(shopData);
                    this.dataForTable1.push(conversionRate1Data);
                    this.dataForTable1.push(conversionRate2Data);
                    this.dataForTable1.push(timeInShop);

                    this.dataForTable2.push(maleData);
                    this.dataForTable2.push(femaleData);

                    this.dataForTable3.push(weekdayData);
                    this.dataForTable3.push(weekendData);

                    this.dataForPercentage2.push(maleDataPercentage);
                    this.dataForPercentage2.push(femaleDataPercentage);

                    this.dataForPercentage3.push(weekdayDataPercentage);
                    this.dataForPercentage3.push(weekendDataPercentage);
                });
        },
    },
    watch: {
        attributeType(val) {
            this.getDataAllShop(
                this.dateRangeTree.dateStart,
                this.dateRangeTree.dateEnd,
                val,
                this.resultType
            );
        },
        
        group(val) {
            this.getDataCompareOverall(
                this.dateRange.dateStart,
                this.dateRange.dateEnd,
                this.compareType,
                val,
                this.previousRepeat,
                this.selectType
            );
        },
        previousRepeat(val) {
            if (val >= 11 && val < 14) {
                this.spMargin4 = 2;
            } else if (val < 11) {
                this.spMargin4 = 8;
            } else if (val == 15) {
                this.spMargin4 = 1;
            }

            this.getDataCompareOverall(
                this.dateRange.dateStart,
                this.dateRange.dateEnd,
                this.compareType,
                this.group,
                val,
                this.selectType
            );
        },
    }
};
</script>
<style lang="scss">
.HeadChart {
    display: flex;
    padding:24px 24px 0 24px;
    justify-content: flex-end;
    align-items: center;
    .lineColor {
        width: 40px;
        height: 4px;
        margin-right: 8px;
        background: #276eff;
        border-radius: 4px;
    }
    .Sublinetext {
        color: #1f1f1f;
        font-size: 14px;
        line-height: 20px;
        font-family: "Roboto";
        font-weight: 400;
    }
}
.custom-button {
    padding: 9px 16px;
    display: flex;
    gap: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    line-height: 20px;
    color: #434343;
    border-radius: 8px;
    border: solid 1px #1f1f1f;
    margin-left: 16px;
    position: relative;
    &:hover {
        .wrapOption {
            visibility: visible;
        }
    }
    .wrapOption {
        background: #ffffff;
        max-height: 320px;
        overflow: auto;
        box-sizing: border-box;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        position: absolute;
        top: 40px;
        z-index: 9999;
        visibility: hidden;
        .Option {
            padding: 16px;
            font-family: "Roboto";
            font-size: 14px;
            color: #272833;
            font-weight: 400;
            width: 212px;
            cursor: pointer;
            &:hover {
                background-color: #f6f9ff;
            }
        }
    }
}
.wrapTableBalance {
    
    line {
        display: none !important;
    }
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .wrapStore {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        .custom-button-menu {
            padding: 9px 16px;
            display: flex;
            gap: 8px;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            align-items: center;
            line-height: 20px;
            color: #434343;
            border-radius: 8px;
            border: solid 1px #1f1f1f;
            margin-left: 16px;
            &:hover {
                .wrapOption {
                    visibility: visible !important;
                }
            }
        }
        .wrapOption {
            background: #ffffff;
            box-sizing: border-box;
            max-height: 320px;
            overflow: auto;
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            
            position: absolute;
            top: 40px;
            z-index: 9999;
            visibility: hidden;
            .Option {
                padding: 16px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 150%;
                color: #272833;
                min-width: 180px;
                font-weight: 400;
                cursor: pointer;
                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
    }

    .wrapSelect {
        display: flex;
        justify-items: center;
        gap: 16px;
        .titleButton {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #434343;
        }
        .custom-button-menu {
            padding: 9px 16px;
            display: flex;
            gap: 8px;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            align-items: center;
            
            line-height: 20px;
            color: #434343;
            border-radius: 8px;
            border: solid 1px #1f1f1f;
            margin-left: 16px;
            position: relative;
            &:hover {
                .wrapOption {
                    visibility: visible !important;
                }
            }
            .wrapOption {
                background: #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                width: 250px !important;
                max-height: 320px;
                overflow: auto;
                position: absolute;
                top: 40px;
                z-index: 9999;
                visibility: hidden;
                .Option {
                    padding: 16px;
                    font-family: "Roboto";
                    font-size: 14px;
                    color: #272833;
                    min-width: 180px;
                    font-weight: 400;
                    width: 212px;
                    cursor: pointer;
                    &:hover {
                        background-color: #f6f9ff;
                    }
                }
            }
        }

        .wrapTimpicker {
            display: flex;
            align-items: center;
            .titleButton {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #434343;
                margin-right: 8px;
            }
            
        }
        .custom-select {
            display: flex;
            height: 44px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            width: 116px;
            border: 1px solid #1f1f1f;
            box-sizing: border-box;
            border-radius: 8px;
            .Select {
                width: 100%;
                height: 100%;
                border: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #1f1f1f;
            }
            option {
                height: 36px;
                border-radius: 8px;
                border: 0px solid;
            }
            option:hover {
                background-color: #edf3ff;
            }
        }
    }
}

.md-table-head {
    background-color: #f0f0f0;
    .md-switch {
        margin: 6px;
    }
    .hidden {
        visibility: hidden !important;
    }
}

.apexcharts-menu-icon {
    display: none;
}
.apexcharts-title-text {
    display: none;
}
.lineColor {
    width: 40px;
    height: 4px;
    margin-right: 8px;
    background: #276eff;
    border-radius: 4px;
}
.Sublinetext {
    color: #1f1f1f;
    font-size: 14px;
    line-height: 20px;
    font-family: "Roboto";
    font-weight: 400;
}
.wrapTableBalance1 {
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 24px 0 24px 24px;
    display: flex;
    flex-direction: column;
    
    .wrapSelect {
        display: flex;
        justify-items: center;
        gap: 16px;
        .custom-select {
            display: flex;
            height: 44px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            width: 116px;
            border: 1px solid #1f1f1f;
            box-sizing: border-box;
            border-radius: 8px;
            .Select {
                width: 100%;
                height: 100%;
                border: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #1f1f1f;
            }
            option {
                height: 36px;
                border-radius: 8px;
                border: 0px solid;
            }
            option:hover {
                background-color: #edf3ff;
            }
        }
    }
}
.HeadChart {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 24px 24px 0 24px;
                .lineColor {
                    width: 40px;
                    height: 4px;
                    margin-right: 8px;
                    background: #276eff;
                    border-radius: 4px;
                }
                .Sublinetext {
                    color: #1f1f1f;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: "Roboto";
                    font-weight: 400;
                }
            }
.TableCustom {
    .md-table-cell-container {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: #272833;
    }
    .md-table-head {
        background-color: #f0f0f0;
        .md-switch {
            margin: 6px;
        }
        .hidden {
            visibility: hidden !important;
        }
    }
    .md-table-head-label {
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        color: #272833;
        text-align: center;
    }
    .md-table-cell path:nth-child(1) {
        display: none !important ;
    }

    .md-table-cell-container {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: #272833;
        text-align: center;
    }
    .md-table-head:first-child {
        width: 290px;
        min-width: 290px;
        .md-table-head-container {
            max-width: 312px;
        }
    }
    .md-table-head::nth-child(2) {
        min-width: 160px;
    }
}
.TableCustom2 {
    .md-table-head-label {
        font-family: "Roboto";
        font-weight: 600;
        font-size: 16px;
        color: #272833;
        text-align: center;
        visibility: hidden;
    }
    tr .md-table-head:nth-child(1) {
        .md-table-head-label {
            visibility: visible;
        }
    }
    tr .md-table-head:last-child {
        .md-table-head-label {
            visibility: visible;
        }
    }
    .md-table-cell-container {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #272833;
    }
    .md-table-cell path:nth-child(1) {
        display: none !important ;
    }

    .md-table-head:first-child {
        width: 290px;
        min-width: 290px;
        .md-table-head-container {
            max-width: 312px;
        }
    }
    .md-table-head::nth-child(2) {
        min-width: 160px;
    }
    .md-table-cell-container {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: #272833;
    }
    .md-table-head {
        background-color: #f0f0f0;
        .md-switch {
            margin: 6px;
        }
        .hidden {
            visibility: hidden !important;
        }
    }
}
@media only screen and (min-width: 1536px) {
    .wrapTableBalance1 {
        margin-top: 40px;
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 24px 0 24px 24px;
        display: flex;
        flex-direction: column;
        .wrapSelect {
            display: flex;
            justify-items: center;
            gap: 16px;
            .custom-select {
                display: flex;
                height: 44px;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 16px;
                width: 116px;
                border: 1px solid #1f1f1f;
                box-sizing: border-box;
                border-radius: 8px;
                .Select {
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #1f1f1f;
                }
                option {
                    height: 36px;
                    border-radius: 8px;
                    border: 0px solid;
                }
                option:hover {
                    background-color: #edf3ff;
                }
            }
            
        }
    }
    .TableCustom {
        td {
            min-width: 160px;
        }
        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
        }
        .md-table-head {
            background-color: #f0f0f0;
            .md-switch {
                margin: 6px;
            }
            .hidden {
                visibility: hidden !important;
            }
        }
        .md-table-head-label {
            font-family: "Roboto";
            font-weight: 600;
            font-size: 16px;
            color: #272833;
            text-align: center;
        }
        .md-table-cell path:nth-child(1) {
            display: none !important ;
        }

        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
            text-align: center;
        }
        .md-table-head:first-child {
            width: 290px;
            min-width: 290px;
            .md-table-head-container {
            max-width: 312px;
        }
        }
        .md-table-head::nth-child(2) {
            min-width: 160px;
        }
    }
    .TableCustom2 {
        .md-table-head-label {
            font-family: "Roboto";
            font-weight: 600;
            font-size: 16px;
            color: #272833;
            text-align: center;
            visibility: hidden;
        }
        td {
            min-width: 160px;
        }
        tr .md-table-head:nth-child(1) {
            .md-table-head-label {
                visibility: visible;
            }
        }
        tr .md-table-head:last-child {
            .md-table-head-label {
                visibility: visible;
            }
        }
        .md-table-cell-container {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #272833;
        }
        .md-table-cell path:nth-child(1) {
            display: none !important ;
        }

        .md-table-head:first-child {
            width: 290px;
            min-width: 290px;
        }
        .md-table-head::nth-child(2) {
            width: 160px;
        }
        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
        }
        .md-table-head {
            background-color: #f0f0f0;
            .md-switch {
                margin: 6px;
            }
            .hidden {
                visibility: hidden !important;
            }
        }
    }
    th.md-table-head {
        width: 250px;
    }
}
@media only screen and (min-width: 1920px) {
    th.md-table-head {
        width: 250px;
    }
    .wrapTableBalance1 {
        margin-top: 40px;
        background: #ffffff;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 24px 0 24px 24px;
        display: flex;
        flex-direction: column;
        .wrapSelect {
            display: flex;
            justify-items: center;
            gap: 16px;
            .custom-select {
                display: flex;
                height: 44px;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 16px;
                width: 116px;
                border: 1px solid #1f1f1f;
                box-sizing: border-box;
                border-radius: 8px;
                .Select {
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #1f1f1f;
                }
                option {
                    height: 36px;
                    border-radius: 8px;
                    border: 0px solid;
                }
                option:hover {
                    background-color: #edf3ff;
                }
            }
        }
    }
    .TableCustom {
        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
        }
        .md-table-head {
            background-color: #f0f0f0;
            .md-switch {
                margin: 6px;
            }
            .hidden {
                visibility: hidden !important;
            }
        }
        .md-table-head-label {
            font-family: "Roboto";
            font-weight: 600;
            font-size: 16px;
            color: #272833;
            text-align: center;
        }
        .md-table-cell path:nth-child(1) {
            display: none !important ;
        }

        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
            text-align: center;
        }
        .md-table-head:first-child {
            width: 290px;
            min-width: 290px;
            .md-table-head-container {
            max-width: 312px;
        }
        }
        .md-table-head::nth-child(2) {
            min-width: 160px;
        }
    }
    .TableCustom2 {
        .md-table-head-label {
            font-family: "Roboto";
            font-weight: 600;
            font-size: 16px;
            color: #272833;
            text-align: center;
            visibility: hidden;
        }
        tr .md-table-head:nth-child(1) {
            .md-table-head-label {
                visibility: visible;
            }
        }
        tr .md-table-head:last-child {
            .md-table-head-label {
                visibility: visible;
            }
        }
        .md-table-cell-container {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #272833;
        }
        .md-table-cell path:nth-child(1) {
            display: none !important ;
        }

        .md-table-head:first-child {
            width: 290px;
            min-width: 290px;
            .md-table-head-container {
                max-width: 312px;
            }
        }
        .md-table-head::nth-child(2) {
            min-width: 160px;
        }
        .md-table-cell-container {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            color: #272833;
        }
        .md-table-head {
            background-color: #f0f0f0;
            .md-switch {
                margin: 6px;
            }
            .hidden {
                visibility: hidden !important;
            }
        }
    }
    
}
</style>
