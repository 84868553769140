<template>
    <div class="WrapTableEmployee">
        <table id="customers">
            <tr class="tr-head">
                <th>
                    {{ $t("Employee.EmployeeID") }}
                </th>
                <th
                    style="cursor: pointer;"
                    @click="changeSortName(!ascendingNameValue)"
                >
                    {{ $t("Employee.Name") }}
                    <span class="material-icons" v-if="ascendingNameValue==false">arrow_drop_up</span>
                    <span class="material-icons" v-else>arrow_drop_down</span>
                </th>
                <th>
                    {{ $t("Employee.Position") }}
                </th>
                <th>
                    {{ $t("Employee.Department") }}
                </th>
                <th>
                    {{ $t("Employee.Location") }}
                </th>
                <th>{{ $t("Customers.Edit") }}</th>
            </tr>
            <tr v-for="employee in listEmployeeData" :key="employee.id">
                <td class="border-bottom border-left">{{ employee.employeeID }}</td>
                <td class="border-bottom ">
                    <img :src="employee.image" alt="Italian Trulli" />{{
                        employee.name
                    }}
                    <md-tooltip md-direction="top">{{employee.name}}</md-tooltip>
                </td>
                <td class="border-bottom ">{{ employee.position }}</td>
                <td class="border-bottom ">{{ employee.department }}</td>
                <td class="border-bottom ">{{ employee.listGroup[0] }}</td>
                <td class="border-bottom border-right">
                    <div>
                        <span
                            class="material-icons ColorEdit"
                            @click="clickEditEmployee(employee.id)"
                        >
                            edit
                        </span>
                        <span
                            class="material-icons ColorDelete"
                            @click="callDeleteEmployeeAPI(employee.id)"
                        >
                            delete
                        </span>
                    </div>
                </td>
            </tr>
        </table>

        <div class="pagination">
            <div class="wrapSelectPagination">
                <span class="seeTable">
                    {{ $t("Customers.RowPerPage") }}</span
                >
                <div
                    class="SelectPagination"
                    @click="chooseLimitFilter()"
                    v-click-outside="handleClickOutsideLimitFilter"
                >
                    {{ limit }}
                    <span class="material-icons">arrow_drop_down</span>
                    <div
                        class="wrapOptionPagination"
                        ref="wrapLimitFilterOption"
                    >
                        <div
                            class="OptionActive"
                            ref="limitFive"
                            @click="changeLimit('5', 'limitFive')"
                        >
                            5
                        </div>
                        <div
                            class="Option"
                            ref="limitTen"
                            @click="changeLimit('10', 'limitTen')"
                        >
                            10
                        </div>
                        <div
                            class="Option"
                            ref="limitFifteen"
                            @click="changeLimit('15', 'limitFifteen')"
                        >
                            15
                        </div>
                        <div
                            class="Option"
                            ref="limitTwenty"
                            @click="changeLimit('20', 'limitTwenty')"
                        >
                            20
                        </div>
                    </div>
                </div>
            </div>
            <a style="cursor: pointer;" @click="changePage(-1)"
                >&laquo;</a
            >
            <a
                style="cursor: pointer;"
                @click="changePage(-1)"
                v-if="page != 1"
                >{{ page - 1 }}</a
            >
            <a style="cursor: pointer;" class="active">{{ page }}</a>
            <a
                style="cursor: pointer;"
                @click="changePage(1)"
                v-if="page != totalPage"
                >{{ page + 1 }}</a
            >
            <a style="cursor: pointer;" @click="changePage(1)"
                >&raquo;</a
            >
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import EventBus from "@/EventBus";
import _ from "lodash";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");


export default {
    props: {
        listEmployeeData: Array,
        totalPage: Number,
        page: Number
    },
    mounted() {
        // Get event bus id when delete successfully
        EventBus.$on("idEmployeeDelete", (data) => {
            this.$emit("deleteEmployeeDone", data)
        });

        EventBus.$on("resetFilter", (data) => {
            this.ascendingNameValue = true
            this.ascendingAgeValue = false
        })
    },

    data() {
        var self = this
        return {
            ascendingNameValue: true,
            sortAtribute: false,
            ascendingAgeValue: false,

            filters: "",
            imageEdit: "/img/pencil.png",
            imageDelete: "/img/delete.png",
            
            limit: 5,
        };
    },
    methods: {
        callDeleteEmployeeAPI(employeeID) {
            this.$emit("deleteEmployee", employeeID)
        },
        changeSortName(ascendingNameValue) {
            this.ascendingNameValue = ascendingNameValue;
            this.ascendingAgeValue = false
            this.$emit("changeSortName", ascendingNameValue)
        },
        changeSortAge(ascendingAgeValue) {
            this.ascendingAgeValue = ascendingAgeValue;
            this.ascendingNameValue = true
            this.$emit("changeSortAge", ascendingAgeValue)
        },

        clickDeleteEmployee(val) {
            this.$emit("deleteEmployee", { data: val });
        },
        calculateAge(birthDay) {
            var from = birthDay.split("/");
            var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
            var cur = new Date();
            var diff = cur - birthdateTimeStamp;
            // This is the difference in milliseconds
            var currentAge = Math.floor(diff / 31557600000);
            // Divide by 1000*60*60*24*365.25
            return currentAge;
        },
        clickEditEmployee(val) {
            sessionStorage.setItem("employeeTable", this.currentPage);
            this.$router.push({
                name: "Sửa nhân viên",
                params: {
                    id: val,
                    data: val,
                },
            });
        },
        debounceInput: _.debounce(function (e) {
            this.filters = e.target.value;
        }, 500),

        chooseLimitFilter() {
            this.$refs["wrapLimitFilterOption"].style.position = "absolute";
            this.$refs["wrapLimitFilterOption"].style.display = "block";
            this.$refs["wrapLimitFilterOption"].style.visibility = "visible";
        },
        handleClickOutsideLimitFilter() {
            this.$refs["wrapLimitFilterOption"].style.display = "none";
            this.$refs["wrapLimitFilterOption"].style.visibility = "hidden";
        },
        changeLimit(number, refName) {
            this.limit = number;
            var listLimit = [
                "limitFive",
                "limitTen",
                "limitFifteen",
                "limitTwenty"
            ];
            for (var index = 0; index < listLimit.length; index++) {
                if (listLimit[index] != refName) {
                    this.$refs[listLimit[index]].classList.value = ["Option"];
                } else {
                    this.$refs[listLimit[index]].classList.value = [
                        "OptionActive"
                    ];
                }
            }

            setTimeout(() => {
                this.handleClickOutsideLimitFilter()
            }, 50)
        },

        changePage(number) {
            if (number == -1 && this.page != 1) {
                this.page--;
            }
            if (number == 1 && this.page < this.totalPage) {
                this.page++;
            }
            this.$emit("changePage", this.page)
        },
    },
    watch: {
        limit(val) {
            this.page = 1;
            this.$emit("changeLimit", val)
        }
    },
};
</script>

<style lang="scss">
.WrapTableEmployee {
    margin-top: 24px;
    table {
        border-spacing: 0 !important;
        width: 100%;
    }
    .tr-head {
        height: 80px;
        background-color: #064B6C;
        color: #fff;
        th {
            width: 350px;
            .material-icons {
                vertical-align: middle;
            }
        }
        th:first-child {
            width: 150px !important;
        }
        th:nth-child(2) {
            text-align: left;
            padding-left: 14px;
        }
    }
    td {
        text-align: center;
        height: 64px;
        padding: 8px 12px;
        font-family: "Roboto";
        font-size: 14px;
        color: #272833;
        font-weight: 400;
        img {
            width: 48px;
            height: 48px;
            margin-right: 8px;
        }
    }
    .border-bottom {
        border-bottom: solid 1px #f0f0f0;
    }
    .border-left {
        border-left: solid 1px #f0f0f0;
    }
    .border-right {
        border-right: solid 1px #f0f0f0;
    }
    td:first-child {
        width: 250px !important;
        white-space: nowrap;
        text-align: center;
        overflow: hidden;
        text-overflow: "...";
    }
    td:nth-child(2) {
        min-width: 80px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "...";
    }
    td:last-child {
        .material-icons {
            cursor: pointer;
        }
    }
    td:nth-child(6) {
        max-width: 64px !important;
    }
    .pagination {
        display: inline-block;
        border-radius: 5px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .wrapSelectPagination {
            display: flex;
            align-items: center;
            position: relative;
            .seeTable {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: "Roboto";
            }
            .SelectPagination {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 60px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: "Roboto";
                padding: 8px 8px;
                cursor: pointer;
                border-radius: 8px;
                margin-left: 16px;
                // &:hover {
                //     background-color: #F6F9FF;
                //     .wrapOptionPagination {
                //         display: block;
                //     }
                // }
                .wrapOptionPagination {
                    position: absolute;
                    display: none;
                    padding: 8px;
                    width: 60px;
                    background: #ffffff;
                    box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
                        0px 3px 4px rgba(10, 31, 68, 0.1);
                    border-radius: 10px;
                    top: 40px;
                    right: 0;
                    z-index: 9;
                    .Option {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 8px 16px;
                        width: 100%;
                        height: 36px;
                        font-family: "Roboto";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        cursor: pointer;
                        
                    }
                    .OptionActive {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 8px 16px;
                        width: 100%;
                        height: auto;
                        font-family: "Roboto";
                        background-color: #edf3ff;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pagination a {
        color: black;
        float: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-family: "Roboto";
        padding: 12px 16px;
        text-decoration: none;
    }

    .pagination a.active {
        color: #2b4bf2;
        border-radius: 5px;
    }

    .pagination a:hover:not(.active) {
        background-color: #f6f9ff;
        border-radius: 5px;
        color: #fff;
    }
}
.main-employee-table {
    .search-container {
        width: 14%;
        border: 2px solid #eaeaea;
        border-radius: 10px;
        padding: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        position: relative;
        margin: 20px;
        gap: 10px;

        .base-input {
            width: 100%;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 15px;
            border-color: transparent;
        }
        img {
            width: 20px;
            height: 20px;
        }
        .date-range-attendance {
            position: absolute;
            top: 100%;
        }
    }
    #employee-table {
        width: 100%;
        margin-bottom: 1rem;
        color: #495057;
        border-collapse: collapse;
        table-layout: fixed;

        td {
            word-wrap: break-word;
            white-space: normal;
        }

        thead td {
            cursor: default;
            text-align: center;
        }
        thead tr th:nth-child(2),
        tr td:nth-child(2) {
            width: 13%;
            text-align: left;
        }
        thead tr th:nth-child(3),
        tr td:nth-child(3) {
            text-align: center;
        }

        thead tr th:nth-child(1) {
            width: 8%;
        }

        thead tr th:nth-child(5) {
            width: 25%;
        }

        tbody td {
            text-align: center;
            .profile-image {
                margin: auto;
            }
            .manage-employee-actions {
                display: flex;
                gap: 15px;
                justify-content: center;

                .btn-action {
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    display: flex;
                    cursor: pointer;

                    justify-content: center;
                    align-items: center;
                }

                .btn-edit-employee {
                    background-color: #fdc02e;
                }

                .btn-delete-employee {
                    background-color: #d94444;
                }
            }
        }
    }

    .pagination-people-table {
        float: right;
    }
}
</style>
