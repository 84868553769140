<template>
    <Portal to="wrapper">
        <transition name="form-modal">
            <div v-show="open" class="wrapper-form-modal">
                <div class="form-modal">
                    <div class="button-close-modal" @click="onCancel()">
                        Đóng
                    </div>
                    <div class="profile-avatar">
                        <img :src="dataMember['image']" />
                    </div>
                    <div class="info-member-container">
                        <TextInput
                            title="Trạng thái khách hàng"
                            :value="statusMember()"
                        />
                        <TextInput
                            title="Địa điểm ghé thăm gần nhất"
                            :value="dataMember['groupName']"
                        />
                        <TextInput
                            title="Tên khách hàng"
                            :value="dataMember['name']"
                        />
                        <TextInput
                            title="Số gần ghé thăm"
                            :value="dataMember['visitNumber']"
                        />
                        <TextInput title="Tuổi" :value="dataMember['age']" />
                    </div>
                </div>
            </div>
        </transition>
    </Portal>
</template>

<script>
import { TextInput } from "@/components";
export default {
    name: "FormModal",
    components: {
        TextInput,
    },
    props: {
        open: {
            type: Boolean,
        },
        dataMember: {
            type: Array,
        },
    },
    methods: {
        onCancel() {
            this.$emit("cancel", false);
        },
        statusMember() {
            if (
                this.dataMember["visitNumber"] == "thứ nhất" ||
                this.dataMember["visitNumber"] == 1 ||
                this.dataMember["visitNumber"] == 0
            ) {
                return "Khách hàng mới";
            } else if (
                parseInt(this.dataMember["visitNumber"]) >= 2 &&
                parseInt(this.dataMember["visitNumber"]) < 15
            ) {
                return "Khách hàng quen";
            } else {
                return "Khách hàng vip";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/* transition */

/* ********************* */
.wrapper-form-modal {
    cursor: default;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);

    .form-modal {
        position: relative;
        width: 100%;
        max-width: 50rem;
        overflow-y: auto;
        background-color: white;
        border-radius: 0.2rem;
        font-size: 16px;
        display: flex;
        flex-direction: column;

        .button-close-modal {
            color: #000;
            margin-left: auto;
            cursor: pointer;
            padding: 20px 20px 0;

            &:hover {
                opacity: 50%;
            }
        }

        .profile-avatar {
            display: flex;
            justify-content: center;
            margin: 30px 0;

            img {
                width: 150px;
                height: 150px;
                object-fit: cover;
            }
        }

        .info-member-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .wrapper-form-modal {
        .form-modal {
            .profile-avatar {
                display: flex;
                justify-content: center;
                margin: 10px 0 30px;

                img {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}
</style>
